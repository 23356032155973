import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import "assets/css/style.css";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { LINK_API } from "constants/API";
import { IMG_LOAD } from "constants/index.js";
import strings from "constants/strings";
import LocationIcon from "mdi-react/MapMarkerIcon";
import moment from "moment";
import React from "react";
import { OBJModel } from "react-3d-viewer";
import SweetAlert from "react-bootstrap-sweetalert";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import ImageSlider from "../../components/ImageSlider/index.jsx";
import "../../components/ImageSlider/stylecss.css";
import "../../components/ImageSlider/stylescss.scss";
import "./css/animationSwing.css";
import Image360 from "./image360";
import "./lightbox.css"; // This only needs to be imported once in your app
import styles from "./overviewStyle";
import QRCode from "react-qr-code";
// import SpeechText from "./speechText";

let Viewer;
const axios = require("axios");
// const allStyle = {
// 	...styles,
// 	...RaidoStyles,
// 	hotSpotBlack: {
// 		backgroundColor: "black !important",
// 		borderRadius: "50%"
// 	}
// }

const listImage = {
  image1:
    "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
  image3:
    "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo3_d4wpac.jpg",
  image2:
    "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo2_krcknc.jpg",
};
const createTooltipSecond = (hotSpotDiv, args) => {
  const parentDiv = document.createElement("div");
  parentDiv.setAttribute("class", "hotspot");
  const outDiv = document.createElement("div");
  outDiv.setAttribute("class", "out");
  const inDiv = document.createElement("div");
  inDiv.setAttribute("id", args);
  inDiv.setAttribute("class", "in");
  inDiv.setAttribute(
    "style",
    `background-image: url(${listImage[args]}); background-size: cover`
  );
  parentDiv.appendChild(inDiv);
  parentDiv.appendChild(outDiv);
  hotSpotDiv.appendChild(parentDiv);
};
const changeSceneSecond = async (name, x, y, id, hots, sceneId) => {
  if (name === "second") {
    const config = {
      id: hots,
      pitch: x,
      yaw: y,
      type: "scene",
      cssClass: "custom-hotspot",
      createTooltipFunc: createTooltipSecond,
      clickHandlerFunc: () =>
        changeSceneSecond("first", 10, 320, "image2", "hotSpot2", "second"),
      createTooltipArgs: id,
      sceneId: sceneId,
    };
    const config_sub = {
      id: "hotSpot3",
      pitch: 530,
      yaw: 180,
      type: "scene",
      cssClass: "custom-hotspot",
      createTooltipFunc: createTooltipSecond,
      clickHandlerFunc: () =>
        changeSceneSecond("third", 530, 180, "image2", "hotSpot2", "second"),
      createTooltipArgs: "image3",
      sceneId: "third",
    };
    await Viewer.removeHotSpot("hotSpot1");
    await Viewer.removeHotSpot("hotSpot2");
    await Viewer.removeHotSpot("hotSpot3");
    await Viewer.loadScene(name);
    await Viewer.addHotSpot(config);
    await Viewer.addHotSpot(config_sub);
  }
  if (name === "first") {
    const config = {
      id: hots,
      pitch: x,
      yaw: y,
      type: "scene",
      cssClass: "custom-hotspot",
      createTooltipFunc: createTooltipSecond,
      clickHandlerFunc: () =>
        changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"),
      createTooltipArgs: id,
      sceneId: sceneId,
    };
    await Viewer.removeHotSpot("hotSpot1");
    await Viewer.removeHotSpot("hotSpot2");
    await Viewer.removeHotSpot("hotSpot3");
    await Viewer.loadScene(name);
    await Viewer.addHotSpot(config);
  }
  if (name === "third") {
    const config = {
      id: hots,
      pitch: x,
      yaw: y,
      type: "scene",
      cssClass: "custom-hotspot",
      createTooltipFunc: createTooltipSecond,
      clickHandlerFunc: () =>
        changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"),
      createTooltipArgs: id,
      sceneId: sceneId,
    };
    await Viewer.removeHotSpot("hotSpot1");
    await Viewer.removeHotSpot("hotSpot2");
    await Viewer.removeHotSpot("hotSpot3");
    await Viewer.loadScene(name);
    await Viewer.addHotSpot(config);
  }
};
class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      open: false,
      tx: "",
      message: "",
      status: "",
      tomato: false,
      image: require("../../../../assets/img/products/cachuabivang.png"),
      shirts: "",
      text: " ",
      selectedShirt: "",
      uuid: "",
      background: { rgb: { r: 255, g: 255, b: 255 } },
      gs1State: "",
      listLogo: [],
      images360: [],
      checkBox: "",
      titleId: "",
      activation_date: "",
      date: "",
      isSampleGLN: false,
      checkGroupDetail: 0,
      infoDetail: "",
      boxInfo: [],
      statusCT: 1,
      openDialog: false,

      switchMode: false, // biến check chuyển đổi giữa ảnh thường và ảnh 360

      openLightBox: false, // biến check khi click ảnh để hiển thị ảnh full màn hình,
      openCert: false, //

      certificates: [],
      full_certificates: [],
      photoIndex: 0, //Check photoindex cho lightbox của certificate

      //Panorama Image
      panoImage:
        "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
      image1:
        "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
      image3:
        "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo3_d4wpac.jpg",
      image2:
        "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo2_krcknc.jpg",
      checkPanorama: false,
      changeImage: false,
      yaw: 320,
      pitch: 10,
      pitchInfo: 40,
      dynamicContent: "",
      color: "#e4261e",
      width: window.innerWidth,
    };
    this.sendFeedBack = this.sendFeedBack.bind(this);
    this.handleLoadingInfo = this.handleLoadingInfo.bind(this);
    this.hanldeClickImage = this.hanldeClickImage.bind(this);
    this.hotspotTooltip = this.hotspotTooltip.bind(this);
    this.handlePano = this.handlePano.bind(this);
    //	this.handleScroll = this.handleScroll.bind(this)
  }
  async hotspotTooltip(hotSpotDiv) {
    //	console.log(hotSpotDiv)
    const parentDiv = document.createElement("div");
    parentDiv.setAttribute("class", "hotspot");
    const outDiv = document.createElement("div");
    outDiv.setAttribute("class", "out");
    const inDiv = document.createElement("div");
    inDiv.setAttribute("id", "image2");
    inDiv.setAttribute("class", "in");
    inDiv.setAttribute(
      "style",
      `background-image: url("${this.state.image2}"); background-size: cover`
    );

    await parentDiv.appendChild(inDiv);
    await parentDiv.appendChild(outDiv);
    await hotSpotDiv.appendChild(parentDiv);
  }
  handleScroll() {
    if (this.props.scrollToMa) {
      this.props.scrollToMa();
    }
  }
  handleChangeImageCert(index) {
    this.setState({
      photoIndex: index,
      openCert: true,
    });
  }
  hanldeClickImage() {
    if (this.state.changeImage === false) {
      this.setState({
        changeImage: true,
        yaw: 550,
        pitch: 25,
        pitchInfo: 40,
        panoImage: this.state.image2,
      });
    } else {
      this.setState({
        changeImage: false,
        yaw: 340,
        pitch: 25,
        pitchInfo: 40,
        panoImage: this.state.image1,
      });
    }
  }
  async componentDidMount() {
    window.addEventListener("resize", this.updateScreenWidth);

    if (this.props.data) {
      await this.handleLoadingInfo(this.props.data);
    }
    await this.setState({
      uuid: this.props.data.id,
    });
    if (this.state.uuid === "00000003") {
      await this.handlePano(this.state.image1);
    }
    if (this.props.certificates.data) {
      if (this.props.certificates.data.length > 0) {
        this.setState({
          certificates: this.props.certificates.data,
          full_certificates: this.props.certificates.dataFull,
        });
      }
    }
  }

  async componentDidUpdate(preProps) {
    if (preProps.data !== this.props.data) {
      this.handleLoadingInfo(this.props.data);
    }
    if (preProps.certificates !== this.props.certificates) {
      if (this.props.certificates.data) {
        if (this.props.certificates.data.length > 0) {
          this.setState({
            certificates: this.props.certificates.data,
            full_certificates: this.props.certificates.dataFull,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handlePano() {
    Viewer = window.pannellum.viewer("panorama", {
      type: "equirectangular",
      default: {
        firstScene: "first",
      },
      scenes: {
        first: {
          panorama: this.state.image1,
          hotSpots: [
            {
              id: "hotSpot2",
              pitch: 10,
              yaw: 320,
              type: "scene",
              cssClass: "custom-hotspot",
              createTooltipFunc: createTooltipSecond,
              clickHandlerFunc: () =>
                changeSceneSecond(
                  "second",
                  550,
                  25,
                  "image1",
                  "hotSpot1",
                  "first"
                ),
              createTooltipArgs: "image2",
              sceneId: "second",
            },
          ],
        },
        second: {
          panorama: this.state.image2,
          hotSpots: [],
        },
        third: {
          panorama: this.state.image3,
          hotSpots: [],
        },
      },
      autoLoad: true,
      showControls: false,
      orientationOnByDefault: true,
    });
    document.getElementById("pan-up").addEventListener("click", function () {
      Viewer.setPitch(Viewer.getPitch() + 10);
    });
    document.getElementById("pan-down").addEventListener("click", function () {
      Viewer.setPitch(Viewer.getPitch() - 10);
    });
    document.getElementById("pan-left").addEventListener("click", function () {
      Viewer.setYaw(Viewer.getYaw() - 10);
    });
    document.getElementById("pan-right").addEventListener("click", function () {
      Viewer.setYaw(Viewer.getYaw() + 10);
    });
    document
      .getElementById("image-2")
      .addEventListener("click", () =>
        changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first")
      );
    document
      .getElementById("image-1")
      .addEventListener("click", () =>
        changeSceneSecond("first", 10, 320, "image2", "hotSpot2", "second")
      );
    document
      .getElementById("image-3")
      .addEventListener("click", () =>
        changeSceneSecond("third", 530, 180, "image2", "hotSpot2", "second")
      );
    document.getElementById("zoom-out").addEventListener("click", function () {
      Viewer.setHfov(Viewer.getHfov() + 10);
    });
    document.getElementById("zoom-in").addEventListener("click", function () {
      Viewer.setHfov(Viewer.getHfov() - 10);
    });
    document
      .getElementById("fullscreen")
      .addEventListener("click", function () {
        Viewer.toggleFullscreen();
      });
  }
  async handleLoadingInfo(data) {
    // let tempImage = [];
    // if (data.logo.length === 1) {
    // 	await tempImage.push({
    // 		src: data.logo[0].original,
    // 	})
    // }
    let language = strings.getLanguage() || "vi";
    let dynamicContent = "";
    if (language === "en" && data.dynamicContent && data.dynamicContent.en) {
      dynamicContent = data.dynamicContent.en;
    } else if (
      language === "vi" &&
      data.dynamicContent &&
      data.dynamicContent.vi
    ) {
      dynamicContent = data.dynamicContent.vi;
    } else if (
      data.dynamicContent &&
      !data.dynamicContent.en &&
      !data.dynamicContent.vi
    ) {
      dynamicContent = data.dynamicContent;
    }

    await this.setState({
      idCode: data.showId,
      titleId: data.titleId,
      activation_date: data.activation_date,
      status: data.status,
      date: data.date,
      productDescription: data.data.productInfo.description
        ? data.data.productInfo.description
        : "",
      cooName:
        data.data.productInfo.origin && data.data.productInfo.origin.name
          ? data.data.productInfo.origin.name
          : data.data.cooperativeInfo.name,
      cooAddress: data.data.cooperativeInfo.address,
      listLogo: data.logo.length > 0 ? data.logo : [],
      isSampleGLN: data.sample,
      gs1State: data.gs1State,
      checkGroupDetail: data.checkGroupDetail,
      infoDetail: data.detailInfo,
      boxInfo: data.boxInfo,
      checkPanorama: data.panorama,
      typeObject: data.data.type,
      dynamicContent: dynamicContent,
      images360: data.images360 ? data.images360 : [],
      expDate: data.expDate ? data.expDate : "",
      color: data.color ? data.color : "#e4261e",
    });
    if (data.data.type === "PRODUCTTYPE") {
      this.setState({
        idCode: data.data.productInfo.id,
      });
    }

    if (data.data.type === "BOX") {
      this.setState({
        checkBox: true,
        productName: "Thùng " + data.data.productInfo.name,
      });
    } else {
      if (data.data.type === "OBJECT") {
        this.setState({
          productName: data.data.objectInfo.name,
        });
      } else {
        this.setState({
          productName: data.data.productInfo.name,
        });
      }
    }
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  async sendFeedBack() {
    this.handleCloseDialog();
    const data = {
      uuidOfProduct: this.state.idCode,
      message: this.state.message,
      email: "test@gmail.com",
      phone: "0374254467",
    };

    this.setState({
      alert: (
        <SweetAlert
          // success
          style={{ display: "block" }}
          title={strings.feedback_processing}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      ),
    });
    axios
      .post(LINK_API.SEND_FEEDBACK, data)
      .then(async () => {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-270px" }}
              title={strings.feedback_success}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            />
          ),
        });
      })
      .catch(() => {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title={strings.feedback_failed}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            />
          ),
        });
      });
  }
  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };
  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };
  handleChange = () => (event) => {
    this.setState({ message: event.target.value });
  };

  handleRentOpen = () => {
    this.setState({ rent: true });
  };

  handleRentClose = () => {
    this.setState({ rent: false });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true, tx: tx });
  };
  selectShirt(thumb) {
    this.setState({ selectedShirt: thumb }, (_) => this.forceUpdate());
  }

  updateScreenWidth = () => this.setState({ width: window.innerWidth });

  renderBasicInfo = (mode) => {
    const { classes } = this.props;
    return mode === "mobile" ? (
      <GridContainer className={classes.noMargin}>
        <GridItem col={12} id="text" style={{ padding: "0" }}>
          <Typography gutterBottom variant="h1" className={classes.productName}>
            {this.state.productName}
          </Typography>
        </GridItem>

        <GridItem
          col={12}
          className="styleMarginAddress"
          style={{ padding: "0" }}
        >
          <GridContainer className={classes.noMargin}>
            <div
              className={classes.iconhv}
              style={{ backgroundColor: this.state.color }}
            >
              {/* <img   id="#addressIcon" onClick={() => this.handleScroll()}  alt="harvest" className={classes.badgeIcon} src={harvest} /> */}
              <LocationIcon
                id="#addressIcon"
                className={classes.badgeIcon}
                style={{ fill: "#fff" }}
                onClick={() => this.handleScroll()}
              />
            </div>
            <GridItem xs={10} md={10} sm={10} className={classes.cooInfo}>
              <p
                onClick={() => this.handleScroll()}
                className={classes.cooName}
              >
                {this.state.cooName}
              </p>
              <p
                onClick={() => this.handleScroll()}
                className={classes.cooAddress}
              >
                {this.state.cooAddress}
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem className="mt-2" col={6} xs={6} style={{ padding: "0" }}>
          <GridContainer>
            <GridItem xs={12} md={12} style={{ padding: "0" }}>
              {this.state.typeObject !== "OBJECT" &&
              this.state.typeObject !== "BOX" ? (
                <div
                  className={classes.textCenter}
                  style={{
                    backgroundColor: this.state.color + "12",
                    borderRadius: "5px",
                    padding: "8px 0px",
                  }}
                >
                  <Typography
                    variant="h1"
                    className={classes.textGenuie}
                    style={{ color: this.state.color }}
                  >
                    {strings.genProduct}
                  </Typography>{" "}
                </div>
              ) : null}
            </GridItem>
            <GridItem xs={12} md={12} style={{ textAlign: "center", marginLeft: "31px" }}>
              <GridContainer className="flexcenter">
                <GridItem
                  xs={12}
                  className={
                    this.state.date
                      ? classes.paddingDateOne
                      : classes.paddingDate
                  }
                >
                  {this.state.checkBox ? (
                    <div>
                      <Typography variant="h1" className={classes.textLight}>
                        {this.state.titleId}
                      </Typography>
                      <Typography variant="h1" className={classes.textCode}>
                        {this.state.idCode}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography variant="h1" className={classes.textLight}>
                        {this.state.titleId}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }}
                      >
                        <Typography variant="h1" className={classes.textCode}>
                          {this.state.idCode}
                        </Typography>
                        {this.state.gs1State === "COFFEE" ? (
                          <img
                            alt="lookup"
                            className="imageFind"
                            src={require("assets/icons/magnifier.svg")}
                            onClick={() => {
                              window.open(
                                "http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938522156580",
                                "_blank"
                              );
                            }}
                          />
                        ) : this.state.gs1State === "HERB" ||
                          this.state.gs1State === "SPINACH" ? (
                          <img
                            alt="lookup2"
                            className="imageFind"
                            src={require("assets/icons/magnifier.svg")}
                            onClick={() => {
                              window.open(
                                "http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938525767059",
                                "_blank"
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </GridItem>
                {this.state.expDate ? (
                  <GridItem xs={12} className={classes.paddingDate}>
                    <Typography
                      variant="h1"
                      className={classes.textLight}
                      style={{ marginTop: "10px" }}
                    >
                      {strings.expDate}
                    </Typography>
                    <Typography variant="h1" className={classes.textCode}>
                      {moment(this.state.expDate).format("DD/MM/YYYY")}
                    </Typography>
                  </GridItem>
                ) : null}
                {this.state.date ? (
                  <GridItem xs={12} className={classes.paddingDateThree}>
                    <Typography
                      variant="h1"
                      className={classes.textLight}
                      style={{ marginTop: "10px" }}
                    >
                      {this.state.activation_date}
                    </Typography>
                    <Typography variant="h1" className={classes.textCode}>
                      {moment(this.state.date).format("DD/MM/YYYY")}
                    </Typography>
                  </GridItem>
                ) : null}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem className="mt-2 text-left" col={6} xs={6}>
          <div>
            <QRCode
              value={`https://etrace.kontum.vn/q/${this.state.idCode}`}
              size={120}
            />
          </div>
        </GridItem>
      </GridContainer>
    ) : (
      <GridContainer className={classes.noMargin}>
        <GridItem xs={12} lg={8} md={9} sm={8} style={{ padding: "0" }}>
          <GridContainer className={classes.noMargin}>
            <GridItem
              xs={12}
              md={12}
              sm={12}
              id="text"
              style={{ padding: "0" }}
            >
              <Typography
                gutterBottom
                variant="h1"
                className={classes.productName}
              >
                {this.state.productName}
              </Typography>
            </GridItem>

            <GridItem
              xs={12}
              md={12}
              className="styleMarginAddress"
              style={{ padding: "0" }}
            >
              <GridContainer className={classes.noMargin}>
                <div
                  className={classes.iconhv}
                  style={{ backgroundColor: this.state.color }}
                >
                  {/* <img   id="#addressIcon" onClick={() => this.handleScroll()}  alt="harvest" className={classes.badgeIcon} src={harvest} /> */}
                  <LocationIcon
                    id="#addressIcon"
                    className={classes.badgeIcon}
                    style={{ fill: "#fff" }}
                    onClick={() => this.handleScroll()}
                  />
                </div>
                <GridItem xs={10} md={10} sm={10} className={classes.cooInfo}>
                  <p
                    onClick={() => this.handleScroll()}
                    className={classes.cooName}
                  >
                    {this.state.cooName}
                  </p>
                  <p
                    onClick={() => this.handleScroll()}
                    className={classes.cooAddress}
                  >
                    {this.state.cooAddress}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} md={12} style={{ padding: "0" }}>
              {this.state.typeObject !== "OBJECT" &&
              this.state.typeObject !== "BOX" ? (
                <div
                  className={classes.textCenter}
                  style={{
                    backgroundColor: this.state.color + "12",
                    borderRadius: "5px",
                    padding: "8px 0px",
                  }}
                >
                  <Typography
                    variant="h1"
                    className={classes.textGenuie}
                    style={{ color: this.state.color }}
                  >
                    {strings.genProduct}
                  </Typography>{" "}
                </div>
              ) : null}
            </GridItem>
            <GridItem xs={12} md={12} style={{ textAlign: "center" }}>
              <GridContainer className="flexcenter">
                <GridItem
                  xs={4}
                  className={
                    this.state.date
                      ? classes.paddingDateOne
                      : classes.paddingDate
                  }
                >
                  {this.state.checkBox ? (
                    <div>
                      <Typography variant="h1" className={classes.textLight}>
                        {this.state.titleId}
                      </Typography>
                      <Typography variant="h1" className={classes.textCode}>
                        {this.state.idCode}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography
                        variant="h1"
                        className={classes.textLight}
                        style={{ marginTop: "10px" }}
                      >
                        {this.state.titleId}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h1" className={classes.textCode}>
                          {this.state.idCode}
                        </Typography>
                        {this.state.gs1State === "COFFEE" ? (
                          <img
                            alt="lookup"
                            className="imageFind"
                            src={require("assets/icons/magnifier.svg")}
                            onClick={() => {
                              window.open(
                                "http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938522156580",
                                "_blank"
                              );
                            }}
                          />
                        ) : this.state.gs1State === "HERB" ||
                          this.state.gs1State === "SPINACH" ? (
                          <img
                            alt="lookup2"
                            className="imageFind"
                            src={require("assets/icons/magnifier.svg")}
                            onClick={() => {
                              window.open(
                                "http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938525767059",
                                "_blank"
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </GridItem>
                {this.state.expDate ? (
                  <GridItem xs={4} className={classes.paddingDate}>
                    <Typography
                      variant="h1"
                      className={classes.textLight}
                      style={{ marginTop: "10px" }}
                    >
                      {strings.expDate}
                    </Typography>
                    <Typography variant="h1" className={classes.textCode}>
                      {moment(this.state.expDate).format("DD/MM/YYYY")}
                    </Typography>
                  </GridItem>
                ) : null}
                {this.state.date ? (
                  <GridItem xs={4} className={classes.paddingDateThree}>
                    <Typography
                      variant="h1"
                      className={classes.textLight}
                      style={{ marginTop: "10px" }}
                    >
                      {this.state.activation_date}
                    </Typography>
                    <Typography variant="h1" className={classes.textCode}>
                      {moment(this.state.date).format("DD/MM/YYYY")}
                    </Typography>
                  </GridItem>
                ) : null}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem
          xs={12}
          md={3}
          sm={4}
          lg={4}
          className={classes.textCenter}
        >
          <div style={{ paddingTop: "30px" }}>
            <QRCode
              value={`https://etrace.kontum.vn/q/${this.state.idCode}`}
              size={150}
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { classes } = this.props;
    //const selector = useCallback(voices => [...voices].find(v => v.lang === 'vi-VN'), []);
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* {this.state.images360.length > 0 ? <script src={"https://cdnjs.cloudflare.com/ajax/libs/pannellum/2.5.6/pannellum.js"}></script> :null} */}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ textAlign: "center" }}
          className={classes.noPadding}
        >
          <Card>
            <CardBody>
              <GridContainer
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
                className={classes.noMargin}
                //Logo
              >
                {this.state.listLogo.length > 1 ? (
                  <GridItem
                    xs={12}
                    className="styleBackgroundOverview"
                    id="mobileImg"
                  >
                    <ImageSlider
                      items={this.state.listLogo}
                      lazyLoad={true}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      infinite={true}
                      autoPlay={true}
                      showNav={false}
                      useBrowserFullscreen={false}
                      slideOnThumbnailOver={true}
                      disableArrowKeys={true}
                    />
                  </GridItem>
                ) : this.state.listLogo[0] ? (
                  this.state.typeObject !== "XETANG" &&
                  this.state.typeObject !== "XETANG1" &&
                  this.state.typeObject !== "CITY" ? (
                    !this.state.switchMode ? (
                      <div className="styleBackgroundOverview">
                        <img
                          alt="logo"
                          className={classes.imageLogo}
                          onClick={() => this.setState({ openLightBox: true })}
                          src={
                            this.state.listLogo[0].thumbnail &&
                            Array.isArray(this.state.listLogo[0].thumbnail) &&
                            this.state.listLogo[0].thumbnail.length
                              ? this.state.listLogo[0].thumbnail[0]
                              : this.state.listLogo[0].thumbnail
                              ? this.state.listLogo[0].thumbnail
                              : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                          }
                        />
                        {this.state.openLightBox && (
                          <Lightbox
                            mainSrc={this.state.listLogo[0].original}
                            onCloseRequest={() =>
                              this.setState({ openLightBox: false })
                            }
                          />
                        )}
                        {this.state.images360.length > 0 ? (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.switchMode}
                                color="primary"
                                onChange={(event) =>
                                  this.setState({
                                    switchMode: !this.state.switchMode,
                                  })
                                }
                                value={this.state.switchMode}
                              />
                            }
                            classes={{
                              label: classes.label,
                            }}
                            label={strings.Image360}
                          />
                        ) : null}
                      </div>
                    ) : null
                  ) : this.state.typeObject === "XETANG" ? (
                    <OBJModel
                      width={390}
                      height={371}
                      src={this.state.listLogo[0].original}
                      texPath=""
                    />
                  ) : this.state.typeObject === "CITY" ? (
                    <div style={{ width: "100%" }}>
                      <div id="panorama">
                        <div id="controls">
                          <div className="ctrl" id="pan-up">
                            &#9650;
                          </div>
                          <div className="ctrl" id="pan-down">
                            &#9660;
                          </div>
                          <div className="ctrl" id="pan-left">
                            &#9664;
                          </div>
                          <div className="ctrl" id="pan-right">
                            &#9654;
                          </div>
                          <div className="ctrl" id="image-1">
                            1
                          </div>
                          <div className="ctrl" id="image-2">
                            2
                          </div>
                          <div className="ctrl" id="image-3">
                            3
                          </div>
                          <div className="ctrl" id="zoom-in">
                            &#43;
                          </div>
                          <div className="ctrl" id="zoom-out">
                            &minus;
                          </div>
                          <div className="ctrl" id="fullscreen">
                            &#x2922;
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="sketchfab-embed-wrapper">
                      <iframe
                        title="A 3D model"
                        className="style3D"
                        src="https://sketchfab.com/models/14c74d148326448c8edb5fee81be3894/embed?autostart=1"
                        frameborder="0"
                        allow="autoplay; fullscreen; vr"
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                      ></iframe>
                    </div>
                  )
                ) : null}
                {this.state.switchMode ? (
                  <div className="styleBackgroundOverview">
                    <Image360 />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.switchMode}
                          color="primary"
                          onChange={(event) =>
                            this.setState({
                              switchMode: !this.state.switchMode,
                            })
                          }
                          value={this.state.switchMode}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={strings.Image360}
                    />
                  </div>
                ) : null}

                <GridItem md={10} style={{ padding: "0" }}>
                  {this.state.width < 600
                    ? this.renderBasicInfo("mobile")
                    : this.renderBasicInfo()}
                </GridItem>

                {!this.state.isSampleGLN ? (
                  this.state.status ? (
                    <GridItem xs={12}>
                      <Typography
                        variant="h1"
                        className={classes.textLight}
                        style={{ marginTop: "10px" }}
                      >
                        {strings.status}
                      </Typography>
                      <Typography variant="h1" className={classes.textCode}>
                        {this.state.status}
                      </Typography>
                    </GridItem>
                  ) : null
                ) : null}
              </GridContainer>
              <GridContainer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <GridItem xs={12} md={10} className={classes.textStart}>
                  {this.state.certificates.length > 0 ? (
                    <div style={{ width: "100%", textAlign: "start" }}>
                      <p className={classes.detailtitle}>
                        {strings.certQuality}
                      </p>
                    </div>
                  ) : null}
                  {this.props.certificates.data.length > 0 ? (
                    <div style={{ textAlign: "start" }}>
                      {this.state.certificates.map((item, indexCert) => (
                        <img
                          key={indexCert}
                          src={item}
                          alt={"image" + indexCert}
                          onClick={() => this.handleChangeImageCert(indexCert)}
                          className={classes.imageCert}
                        />
                      ))}
                      {this.state.openCert ? (
                        <Lightbox
                          mainSrc={
                            this.state.full_certificates[this.state.photoIndex]
                          }
                          nextSrc={
                            this.state.full_certificates[
                              (this.state.photoIndex + 1) %
                                this.state.full_certificates.length
                            ]
                          }
                          prevSrc={
                            this.state.full_certificates[
                              (this.state.photoIndex +
                                this.state.full_certificates.length -
                                1) %
                                this.state.full_certificates.length
                            ]
                          }
                          onCloseRequest={() =>
                            this.setState({ openCert: false })
                          }
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex:
                                (this.state.photoIndex +
                                  this.state.full_certificates.length -
                                  1) %
                                this.state.full_certificates.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex:
                                (this.state.photoIndex + 1) %
                                this.state.certificates.length,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {this.state.alert}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.overview,
    certificates: state.certificate,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Overview));
