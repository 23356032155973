import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNewsList, getOpInfoList } from "actions/news";
import { getMediaList } from "actions/media";
import { cleanStore } from "actions/index";
import { Container, Row, Col } from "reactstrap";
import CalendarIcon from "mdi-react/CalendarIcon";
import { Pagination } from "@material-ui/lab";
import { stripHtml, truncate } from "../../../utils";
import Skeleton from "@material-ui/lab/Skeleton";

PostList.propTypes = {};

function PostList(props) {
  const { loading } = props;
  const [newsList, setNewsList] = useState([]);
  const [opInfoList, setopInfoList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const path = props.match.path;

  useEffect(() => {
    switch (path) {
      case "/tin-tuc":
        props.getNewsList({ page, per_page: 5 });
        break;
      case "/thong-tin-dieu-hanh":
        props.getOpInfoList({ page, per_page: 5 });
        break;
      default:
        props.history.push("/info");
        break;
    }
    props.getMediaList();

    return () => props.cleanStore();
  }, [page]);

  useEffect(() => {
    const data = props.newsList || [];
    if (props.total) {
      const numberPage = Math.ceil(props.total / 5);
      setCount(numberPage);
    }
    setNewsList(data);
  }, [props.newsList]);

  useEffect(() => {
    const data = props.opInfoList || [];
    setopInfoList(data);
    if (props.total) {
      const numberPage = Math.ceil(props.total / 5);
      setCount(numberPage);
    }
  }, [props.opInfoList]);

  useEffect(() => {
    const mediaData = props.mediaList || [];
    if (mediaData.length && props.newsList && props.newsList.length) {
      const filteredMedia = props.newsList.map((ele, idx) => {
        return mediaData.find((o) => ele.featured_media === o.id) || {};
      });
      setMediaList(filteredMedia);
    }
  }, [props.mediaList, props.newsList]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };
  
  return (
    <div>
      <Container style={{minHeight: "560px"}}>
        <Row className="mt-3">
          <Col>
          {path === "/tin-tuc" ? (
            <div className="w-100 border-red pb-2">
              <div className="font-weight-bold d-inline-block p-2 mt-3 ">
                <span className="text-red">
                  TIN TỨC NỔI BẬT
                </span>
              </div>
            </div>
          ) : (
            <div className="w-100 border-red pb-2">
              <div className="font-weight-bold d-inline-block p-2 mt-3 ">
                <span href="/thong-tin-dieu-hanh" className="text-red">
                  THÔNG TIN ĐIỀU HÀNH
                </span>
              </div>
            </div>
          )}
          </Col>
        </Row>
        {loading ? (
          [1, 2, 3, 4].map((item, idx) => {
            return (
              <>
                <Row key={idx} className="my-2 d-flex flex-row mb-1"style={{width: "96%"}}>
                  <Col xs={5} md={4}>
                    <img
                      className="mx-2"
                      src={
                        "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                      }
                      alt="noImage"
                    />
                  </Col>
                  <Col xs={6} md={6} className="d-flex flex-column">
                    <Skeleton animation="ware" />
                    <Skeleton animation="ware" />
                    <Skeleton animation="ware" />
                  </Col>
                </Row>
                <hr style={{ width: "100%" }} />
              </>
            );
          })
        ) : (
          <Row className="mt-3">
            <Col>
            {path === "/tin-tuc"
              ? newsList.map((item, idx) => {
                  return (
                    <>
                      <Row key={idx} className="my-2 d-flex flex-row mb-1" style={{width: "96%"}}>
                        <Col xs={5} md={4}>
                          <img
                            className="mx-2"
                            src={
                              mediaList[idx] && mediaList[idx].source_url
                                ? mediaList[idx].source_url
                                : "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                            }
                            alt="noImage"
                          />
                        </Col>
                        <Col xs={6} md={6} className="d-flex flex-column">
                          <a
                            href={`/tin-tuc/${item.id}/${item.slug}`}
                            title={stripHtml(item.title.rendered)}
                            className="main-title text-dark font-weight-bold"
                          >
                            {stripHtml(item.title.rendered)}
                          </a>
                          <span className="time-text mt-1 mb-1">
                            <CalendarIcon
                              style={{ margin: "0 3px 3px 0" }}
                              size={16}
                            />
                            {moment(item.modified).format("DD/MM/YYYY")}
                          </span>
                          <div
                            className="w-100 mt-1"
                            style={{ textAlign: "justify" }}
                          >
                            <span className="main-short-description">
                              {truncate(stripHtml(item.excerpt.rendered), 150)}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%" }} />
                    </>
                  );
                })
              : opInfoList.map((item, idx) => {
                  return (
                    <>
                      <Row key={idx} className="my-2 d-flex flex-row mb-1">
                        <Col xs={5} md={4}>
                          <img
                            className="mx-2"
                            src={
                              mediaList[idx] && mediaList[idx].source_url
                                ? mediaList[idx].source_url
                                : "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                            }
                            alt="noImage"
                          />
                        </Col>
                        <Col xs={6} md={6} className="d-flex flex-column">
                          <a
                            href={`/thong-tin-dieu-hanh/${item.id}/${item.slug}`}
                            title={stripHtml(item.title.rendered)}
                            className="main-title text-dark font-weight-bold"
                          >
                            {stripHtml(item.title.rendered)}
                          </a>
                          <span className="time-text mt-1 mb-1">
                            <CalendarIcon
                              style={{ margin: "0 3px 3px 0" }}
                              size={16}
                            />
                            {moment(item.modified).format("DD/MM/YYYY")}
                          </span>
                          <div
                            className="w-100 mt-1"
                            style={{ textAlign: "justify" }}
                          >
                            <span className="main-short-description">
                              {truncate(stripHtml(item.excerpt.rendered), 150)}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%" }} />
                    </>
                  );
                })}
            </Col>
          </Row>
        )}

        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <Pagination
              count={count || 1}
              page={page}
              onChange={handleChangePage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  newsList: state.news.newsList,
  mediaList: state.media.mediaList,
  opInfoList: state.news.opInfoList,
  loading: state.news.loading,
  total: state.news.total,
});

const mapDispatchToProps = {
  getNewsList,
  getMediaList,
  getOpInfoList,
  cleanStore,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostList)
);
