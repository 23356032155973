import React from "react"
import Header from "../components/Header"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
// import Advertisements from "../components/Advertisements"
import "../css/index.css"

const ContactPage = (props) => {
    return (
        <>
            {/* <Advertisements/> */}
            <Header/>
            <Contact/>
            <Footer/>
        </>
    )
}
export default ContactPage;