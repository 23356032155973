import { getManufacturerList } from "actions/manufacturer";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { truncate } from "../../../utils";

const Manufacturer = (props) => {
  const getManufacturerList = props.getManufacturerList;

  React.useEffect(() => {
    getManufacturerList();
  }, []);

  const manufacturerList = props.manufacturerList;
  const [listManufacturer, setList] = React.useState([]);
  React.useEffect(() => {
    if (manufacturerList) {
      setList(manufacturerList);
    }
  }, [manufacturerList]);

  const handleSeeDetail = (id) => [props.history.push("/ho-kinh-doanh/" + id)];

  if (!listManufacturer || !listManufacturer.length) {
    return (
      <>
        <Container>
          <Row className="mt-3">
            <Col md="12">
              <div className="w-100 border-red pb-2">
                <div className="d-inline-block pt-2 pb-2 mt-3 ">
                  <span className="font-weight-bold text-red">
                    HỘ KINH DOANH
                  </span>
                </div>
                <a className="fl-right pr-3 mt-4" href="/ho-kinh-doanh">
                  Xem tất cả <ChevronRightIcon />
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="py-2 mt-4 text-center">
                <img
                  style={{ height: "250px" }}
                  src="https://res.cloudinary.com/dinhtq98/image/upload/v1610341723/Kontum%20Project/Asset_2_4x_ftrls3.png"
                  alt="Chua co du lieu"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 border-red pb-2">
              <div className="d-inline-block pt-2 pb-2 mt-3 ">
                <span className="font-weight-bold text-red">HỘ KINH DOANH</span>
              </div>
              <a className="fl-right pr-3 mt-4" href="/ho-kinh-doanh">
                Xem tất cả <ChevronRightIcon />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="mt-4 d-flex flex-wrap-content flex-item-product">
              {listManufacturer.map((item, idx) => {
                if (idx < 8) {
                  console.log();
                  return (
                    <div
                      className="flex-stretch-card-product position-relative col-xs-4"
                      key={idx}
                    >
                      <Card className="no-transition w-100 card-product">
                        <div className="p-4 d-flex flex-column align-items-center card-container">
                          <div
                            onClick={() => handleSeeDetail(item.uuid)}
                            className="ava-product"
                          >
                            <img
                              onClick={() => handleSeeDetail(item.uuid)}
                              className="img-product xy-center"
                              src={
                                item.avatar && item.avatar[0]
                                  ? item.avatar[0]
                                  : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card">
                            <p
                              title={item.name}
                              onClick={() => handleSeeDetail(item.uuid)}
                              className="font-weight-bold mt-3 mb-0 product-label"
                            >
                              {truncate(item.name, 20)}
                            </p>
                            {item.description && (
                              <p
                                title={item.description}
                                className="company-short-description"
                              >
                                {truncate(item.description, 80)}
                              </p>
                            )}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  manufacturerList: state.manufacturerList.manufacturerList,
});
const mapDispatchToProps = {
  getManufacturerList,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Manufacturer)
);
