import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import MessageBulletedIcon from "mdi-react/MessageBulletedIcon";
import { getContact } from "actions/contact";
import renderHTML from 'react-render-html';
const Contact = (props) => {
  const getContact = props.getContact;

  React.useEffect(() => {
    getContact();
  }, [getContact]);

  const contact = props.contact;
  const [infoContact, setInfo] = React.useState({
    contact: ''
  });

  React.useEffect(() => {
    if (contact) {
      setInfo(contact);
    }
  }, [contact]);

  return (
    <>
      <Container style={{ minHeight: "560px" }}>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 pb-2 border-red">
              <div className="font-weight-bold d-inline-block p-2 mt-3 ">
                <span className="text-red">
                  THÔNG TIN LIÊN HỆ
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="12">
            {renderHTML(infoContact.contact)}
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
    contact: state.contact.contact,
});
const mapDispatchToProps = ({
  getContact
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
