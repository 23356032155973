const styles = theme => ({
    root: {
      width: '100%',
      height: '100vh',
      backgroundColor: "black",
      overflow: "hidden",
      display:"flex",
      //justifyContent: "center",
      alignItems: "center",
      flexFlow:"column"
    },
    qrFrame: {
      border: "none"
    },
    header: {
      height: "80px",
      backgroundColor: "black",
      boxShadow: "0 6px 10px -4px rgba(0,0,0,.15)",
      width: "100%",
      padding: "10px",
      backgroundImage: "url(https://res.cloudinary.com/agridential/image/upload/v1583811908/images/header_background_desktop_a8vrfu.png)",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "0px",
    },
    logo: {
      height: "40px",
  
    },
    title1:{
        color: "white",
        fontWeight: "bold",
        fontSize: "16px",
        fontFamily: "Nunito",
        lineHeight: "10px",
        marginTop: "100px"
    },
    title2:{
        color: "white",
        fontWeight: "bold",
        fontSize: "16px",
        fontFamily: "Nunito",
        lineHeight: "10px",
        //marginTop: "100px"
    },
    video: {
      // height: "100%",
      // width:"100%"
    },
    centerCam: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column",
      marginTop: "-50px"
    },
    address: {
        color: "white",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: "bold"
    },
    link:{
        width: "100%",
        textAlign: "center",
        position: "absolute",
        bottom: "20px"
    }
  });
  export default styles;