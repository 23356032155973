import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import { FONT_SIZES, COLORS, FONTS } from '../../../../constants';


  const styles = theme => ({
    ...dashboardStyle,
    ...loginPageStyle,
    logoVBC:{
      height: "50px",
      [theme.breakpoints.down("sm")]: {
        height: "30px",
      },
    },
    weblink:{
      fontSize: "14px",
      fontFamily: "Nunito",
      lineHeight: "25px",
      color: "white",
      marginTop: "23px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        // marginTop: "50px"
      },
    },
    linkweb:{

    },
    addressvbc:{
      height: "46px",
      color: "white",
      fontFamily: "Nunito",
      fontSize: "14px",
      lineHeight: "25px",
      marginBottom: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    logoAGD:{
      height: "50px",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        height: "30px",
      },
    },
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "500",
        fontFamily: "'Nunito', 'Helvetica', 'Arial', sans-serif",
        fontSize: "13px",
        textAlign: 'center',
        marginBottom: "3px",
        textDecoration: "none",
        whiteSpace: "nowrap",
    },
    textCenter: {
        textAlign: 'center'
    },
    productName: {
        fontFamily: FONTS.PRIMARY,
        fontSize: "28px",
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: "nowrap",
        color: COLORS.BLACK,
    },
    manufacName: {
        fontFamily: "'Nunito', 'Helvetica', 'Arial', sans-serif",
        fontSize: "18px",
        fontWeight: "400",
        textAlign: 'center',
        whiteSpace: "nowrap",
        backgroundColor: "#e5f3e5",  
        borderRadius: "6px",
        color:"#4caf50",
        display: "inline-block",
        padding:"10px",
    },
    productAtt: {
        fontFamily: "'Nunito', 'Helvetica', 'Arial', sans-serif",
        fontSize: "17px",
        fontWeight: "300",
        textAlign: 'center',
        whiteSpace: "nowrap",
        paddingTop:"10px"
        // color: "#00bcd4",
    },
    content: {
        backgroundColor: "#F9F9F9",
        padding: "0px 0px !important"
    },
    contentfooter: {
        padding: "30px",
        backgroundColor: "#ffffff",
        fontSize: "14px",
        fontFamily:FONTS.PRIMARY
    },
    logo: {
        width: "140px",
    },
    textLight:{
        color: COLORS.GRAY,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
    },
    textCode: {
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.NORMAL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: '#007ACE',
        // marginTop: '5px',
        // marginBottom: '5px',
    },
    textSample: {
        display:"inline-block",
        padding:"5px",
        fontFamily: FONTS.PRIMARY,
        fontSize: "16px",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#212B36",
        background: "#FCF1CD",
        marginBottom: '10px',
        fontWeight:"600"
    },
    imgProduct: {
        textAlign: 'center',
        backgroundColor: "#F9F9F9",
        padding: "25px 25px !important",
        marginBottom: '20px'
    },
    btStatus: {
        backgroundColor: COLORS.RED_SOFT,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: COLORS.RED,
        textTransform: 'none',
        marginRight: '30px',
    },
    btTrack: {
        backgroundColor: COLORS.GREEN_SOFT,
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        textTransform: 'none',
        color: COLORS.GREEN,
        "&:hover,&:focus": {
            outline: 0,
            boxShadow: "none!important",
        },
    },
    tag: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.LARGE,
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#5a5a5a",
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    barLight: {
      width: '30%',
      borderTop: "2px solid #b7b7b7",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
      overflow: "hidden",
    },
    barDark: {
      width: '25%',
      marginTop:"0px",
      borderTop: "2px solid #000",
    },
    barGreen: {
      width: '26vw',
      marginTop:"0px",
      borderTop: "2px solid #5cb360",
    },
    center: {
      textAlign: 'center',
    },
    noPadding:{
        padding:"0px 0px !important",
    },
    PaddingOut:{
      padding:"0px -20px !important",
  },
    textBlock: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: 0,
    },
    footerText:{
  
        color: "#000000",
        fontWeight: "600",
    },
    icon: {
        display:"inline", width:"40px", height:"40px"
      },
    contactstyle:{
        display:"inline",height:"40px", marginLeft:"10px", margin: "0 auto"
    },
    linebreak:{
        marginBottom: "5px",
        borderTop: "1px solid #b7b7b7"
    },
    textTx: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: 0,
        color: "#007bff",
      },
      txTitle: {
        color: "#333333 !important",
        fontWeight: "400",
        margin: "10px 0px 0px"
      },
      notchedOutline: {
        borderColor: 	"#46494D",
        color: "#46494D",
      },
      multilineColor:{
        color: "#46494D",
      },
      timelineBadge: {
        [theme.breakpoints.down("sm")]: {
          left: "5% !important"
        },
        color: "#FFFFFF",
        width: "50px",
        height: "50px",
        lineHeight: "51px",
        fontSize: "1.4em",
        textAlign: "center",
        position: "absolute",
        top: "16px",
        left: "50%",
        marginLeft: "-24px",
        zIndex: "100",
        borderTopRightRadius: "50%",
        borderTopLeftRadius: "50%",
        borderBottomRightRadius: "50%",
        borderBottomLeftRadius: "50%"
      },timelineSimpleBadge: {
        left: "5%"
      },  
      badgeIcon: {
        width: "25px",
        height: "25px"
      },
      root:{
        width: "919px",
        [theme.breakpoints.down("sm")]: {
          width: "100%"
        },
      },
      rootBot:{
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor:"#181B20",
        [theme.breakpoints.down("sm")]: {
          width: "100%"
        },
      },
      breakarea:{
        width:  "919px",
        [theme.breakpoints.down("sm")]: {
          width: "100%"
        },
        textAlign: "center"
      },
      
    
});
export default styles;