import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getContact } from "actions/contact";
import YoutubeIcon from "mdi-react/YoutubeIcon";
// import FacebookIcon from "mdi-react/FacebookIcon";
// import InstagramIcon from "mdi-react/InstagramIcon";
import { stripHtml } from "../../../utils";

const Footer = (props) => {
  const getContact = props.getContact;

  React.useEffect(() => {
    getContact();
  }, [getContact]);

  const contact = props.contact;
  const [infoContact, setInfo] = React.useState({
    websiteName: "",
    email: "",
    address: "",
    phone: "",
    fax: "",
    logo: {},
  });

  React.useEffect(() => {
    setInfo(contact);
  }, [contact]);
  return (
    <>
      <div
        className="bg-white mt-5"
        style={{ borderTop: "1px solid rgb(222,222,222)" }}
      >
        <Container className="pt-4 pb-3">
          <Row>
            <Col className="mb-2" md="6">
              <p className=" font-weight-bold">
                <span className="font-weight-bold">
                  {infoContact.websiteName}
                </span>
              </p>
              <p>
                Địa chỉ:{" "}
                <span className="font-weight-bold">{infoContact.address}</span>
              </p>
              <p>
                Điện thoại:{" "}
                <span className="font-weight-bold">{infoContact.phone}</span>
              </p>
              <p>
                Fax: <span className="font-weight-bold">{infoContact.fax}</span>
              </p>
              <p>
                Email:{" "}
                <span className="font-weight-bold">{infoContact.email}</span>
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={infoContact.videoLink}
              >
                <YoutubeIcon size={36} color="#FF0000" />
              </a>
            </Col>

            <Col md="6" className="align-items-center">
              <Row>
                <Col md={12}>
                  <div className="border-logo" style={{ width: "fit-content" }}>
                    <img
                      style={{
                        maxWidth: "450px",
                        maxHeight: "100px",
                        width: "100%",
                      }}
                      alt="logo"
                      src={
                        infoContact.logo
                          ? infoContact.logo.secure_url
                          : "https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png"
                      }
                    />
                  </div>
                </Col>
                <Col className="mt-3">
                    <p >{infoContact.description ? stripHtml(infoContact.description) : ''}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  contact: state.contact.contact,
});
const mapDispatchToProps = {
  getContact,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
