import React from "react"
// import Advertisements from "../components/Advertisements"
import Footer from "../components/Footer"
import Header from "../components/Header"
import ProductList from "../components/ProductList"
import "../css/index.css"

const ProductListPage = (props) => {
    return (
        <>
            {/* <Advertisements /> */}
            <Header />
            <ProductList />
            <Footer/>
        </>
    )
}
export default ProductListPage;