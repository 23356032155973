import React from "react"
import Header from "../components/Header"
import ManufacturerDetail from "../components/ManufacturerDetail"
import Footer from "../components/Footer"
// import Advertisements from "../components/Advertisements"
import "../css/index.css"

const ManufacturerDetailPage = (props) => {
    return (
        <>
            {/* <Advertisements /> */}
            <Header />
            <ManufacturerDetail />
            <Footer />
        </>
    )
}
export default ManufacturerDetailPage;