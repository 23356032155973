import React from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import "../css/index.css"
// import PropTypes from 'prop-types';
import Post from "../components/Post"
// import Advertisements from "../components/Advertisements"

function DetailInfo(props) {
    return (
        <>
            {/* <Advertisements /> */}
            <Header/>
            <Post/>
            <Footer/>
        </>
    );
}

export default DetailInfo;