import { Dialog, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.jsx";
import strings from "constants/strings";
import React from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import style from "./introduceStyle";
import OverviewGroup from "./overviewGroup.jsx";
import Taste from "./taste";
import { Feedback } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { LINK_API } from "constants/API";
import { IMG_LOAD } from "constants/index.js";

const axios = require("axios");

function Introduce(...props) {
  const overviewData = props[0].data;
  const classes = props[0].classes;
  const [openDialog, setopenDialog] = React.useState(false);
  const [alert, setalert] = React.useState(null);
  const [feedbackContent, setfeedbackContent] = React.useState("");
  const [multiline, setmultiline] = React.useState("");
  const [noData, setnoData] = React.useState("");
  const [objectInfo, setDetail] = React.useState({
    description: "",
    dynamicContent: "",
    infoDetail: [],
    boxInfo: [],
    typeObject: "",
    checkDetail: "",
  });

  // const handleOpenDialog = () => {
  //   setopenDialog(true);
  // };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const handleChange = (variable) => (event) => {
    if (variable === "feedbackContent") {
      setfeedbackContent(event.target.value);
    } else {
      setmultiline(event.target.value);
    }
  };

  const hideAlert = () => {
    setalert(null);
  };

  const sendFeedBack = async () => {
    handleCloseDialog();
    const data = {
      uuidOfProduct: objectInfo.idCode,
      message: `${feedbackContent} ${multiline}`,
      email: "test@gmail.com",
      phone: "0342788099",
    };

    setalert(
      <SweetAlert
        // success
        style={{ display: "block" }}
        title={strings.feedback_processing}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        showConfirm={false}
      >
        <div style={{ textAlign: "center" }}>
          <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
        </div>
      </SweetAlert>
    );

    axios
      .post(LINK_API.SEND_FEEDBACK, data)
      .then(async () => {
        setalert(
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-270px" }}
            title={strings.feedback_success}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.success}
          />
        );
      })
      .catch(() => {
        setalert(
          <SweetAlert
            error
            style={{ display: "block" }}
            title={strings.feedback_failed}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.success}
          />
        );
      });
  };

  const handleLoadingInfo = (data) => {
    if (
      data.data.objectInfo.description ||
      data.detailInfo ||
      data.data.objectInfo.dynamicContent !== "<p></p>\n" ||
      data.boxInfo.length > 0 ||
      data.typeObject === "FRUIT"
    ) {
      setDetail({
        description: data.data.objectInfo.description
          ? data.data.objectInfo.description
          : "",
        infoDetail: data.detailInfo ? data.detailInfo : {},
        dynamicContent: data.data.objectInfo.dynamicContent,
        checkGroupDetail: data.checkGroupDetail,
        boxInfo: data.boxInfo,
        typeObject: data.data.type,
        isSampleGLN: data.sample,
        idCode: data.showId,
      });
    } else {
      setnoData(true);
    }
  };
  React.useEffect(() => {
    handleLoadingInfo(overviewData);
  }, [overviewData]);

  return (
    <div
      style={{
        textAlign: "justify",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexFlow: "row",
        marginTop: "20px",
      }}
    >
      {!noData ? (
        <GridItem xs={12} md={10} sm={12} style={{ marginBottom: "25px", padding: "0px 20px" }}>
          <div className="flexcenter" style={{ flexFlow: "column" }}>
            <Typography className={classes.xuatxutag}>
              {strings.introduction_product}
            </Typography>
            <hr className="LineBreakDes"></hr>
          </div>
          {objectInfo.description ? (
            <div>
              {/* <p className="detailtitle">{strings.introduction_product}</p> */}
              {objectInfo.description.split("\n").map((item) => {
                return <Typography className="textdes">{item}</Typography>;
              })}
              <hr className="LineBreakDes" />
            </div>
          ) : null}
          {objectInfo.checkGroupDetail ===
          0 ? null : objectInfo.checkGroupDetail === 1 ? (
            <div>
              {Object.keys(objectInfo.infoDetail).map((overview, index) => (
                <div key={index}>
                  <p className="detailtitle">{overview}</p>
                  <Typography className="textdes" style={{ marginTop: "10px" }}>
                    {objectInfo.infoDetail[overview]}
                  </Typography>
                  {objectInfo.typeObject === "FRUIT" &&
                  index === Object.keys(objectInfo.infoDetail).length - 1 ? (
                    <div className={classes.paddingTaste}>
                      <Taste />
                    </div>
                  ) : null}
                  {index !== Object.keys(objectInfo.infoDetail).length - 1 ? (
                    <hr className="LineBreakDes" />
                  ) : null}
                </div>
              ))}
            </div>
          ) : objectInfo.checkGroupDetail === 2 ? (
            <OverviewGroup overview={objectInfo.infoDetail} />
          ) : (
            <div>
              {objectInfo.infoDetail.map((overview, index) => (
                <div key={index}>
                  <p className="detailtitle">{overview.key}</p>
                  <Typography className="textdes" style={{ marginTop: "10px" }}>
                    {overview.value}
                  </Typography>
                  {index !== objectInfo.infoDetail.length - 1 ? (
                    <hr className="LineBreakDes" />
                  ) : null}
                </div>
              ))}
            </div>
          )}
          {objectInfo.dynamicContent &&
          objectInfo.dynamicContent !== "<p></p>\n" ? (
            <div>
              {renderHTML(objectInfo.dynamicContent)}
              <hr className="LineBreakDes" />
            </div>
          ) : null}
          {objectInfo.boxInfo.map((box, indexBox) => (
            <div key={indexBox}>
              <p className="detailtitle">{box.key}</p>
              <Typography className="textdes" style={{ marginTop: "10px" }}>
                {box.value}
              </Typography>

              <hr className="LineBreakDes" />
            </div>
          ))}
        </GridItem>
      ) : (
        <GridItem
          xs={12}
          className="flexcenter"
          style={{ display: "flex", flexFlow: "column" }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              alt="notfound"
              src="https://res.cloudinary.com/agridential/image/upload/c_scale,w_300/v1589864439/images/No_data_w9gh2h.gif"
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p className={classes.noData}>{strings.noInfo}</p>
          </div>
        </GridItem>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Feedback />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {strings.product_feedback}
            </h4>
          </CardHeader>
          <CardBody style={{ textAlign: "justify" }}>
            <h6 className={classes.textField}>
              {" "}
              {strings.product_feedback_description}
            </h6>
            <TextField
              id="standard-multiline-flexible"
              label={strings.product_feedback_content}
              value={feedbackContent}
              onChange={handleChange("feedbackContent")}
              className={classes.textField}
              margin="normal"
              fullWidth
            />
            <TextField
              id="standard-multiline-flexible"
              label={strings.product_feedback_content_more}
              multiline
              rowsMax="4"
              value={multiline}
              onChange={handleChange("multiline")}
              className={classes.textField}
              margin="normal"
              fullWidth
            />
            <Button color="success" onClick={sendFeedBack}>
              {strings.send}
            </Button>
          </CardBody>
        </Card>
      </Dialog>
      {alert}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.overview,
  };
};

export default withStyles(style)(connect(mapStateToProps, null)(Introduce));
