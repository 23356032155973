import $ from "jquery";
import SearchIcon from "mdi-react/MagnifyIcon";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { getContact } from "actions/contact";
import SupportContact from "../components/SupportContact";

function handleActiveNav() {
  var current = window.location.pathname;
  $("nav ul li a").each(function () {
    var $this = $(this);
    // if the current path is like this link, make it active
    if (
      current.indexOf($this.attr("href")) !== -1 ||
      ((current === "/") &&
        $this.attr("href") === "/trang-chu")
    ) {
      $this.addClass("active");
    }
  });
}

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [search, setSearch] = React.useState("");

  const handleChange = (e) => {
    if (e.keyCode === 13) {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click
      onSearch();
    }
    setSearch(e.target.value);
  };

  const onSearch = () => props.history.push("/q/" + search);
  useEffect(handleActiveNav, []);

  const contact = props.contact;
  const [infoContact, setInfo] = useState({
    logo: {},
  });

  useEffect(() => {
    setInfo(contact);
  }, [contact]);

  return (
    <>
      <div style={{background: "#fff"}}>
        <SupportContact />
        <Container className="header-container">
          <div className="row header-row-container">
            <div className="col-12">
              <div className="row">
                <div className="col-12" style={{ height: "100%" }}>
                  <div
                    className="row d-flex justify-content-center text-center"
                    style={{ height: "100%" }}
                  >
                    <div className="col-12 d-flex justify-content-center">
                      <a href="/">
                        <img
                          className="logo-kontum"
                          src={
                            infoContact.logo
                              ? infoContact.logo.secure_url
                              : "https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png"
                          }
                          alt="logo_Kontum"
                        />
                      </a>
                    </div>
                    <div className="col-md-2 col-sm-0 mt-3"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 xy-center">
              <a href="/">
                <img
                  className="txng-logo"
                  src={
                    "https://res.cloudinary.com/dinhtq98/image/upload/v1607484851/Kontum%20Project/TXNG_KT_Logo_d9bhot.png"
                  }
                  alt="logoVBC"
                />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-dark-red py-2">
        <Container className="nav-container">
          <div className="d-flex justify-content-between content-center">
            <div className="d-flex justify-content-center align-items-center-nav" style={{width: "100%"}}>
              <Navbar style={{paddingLeft: "0"}} expand="lg">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    <NavItem>
                      <NavLink href="/trang-chu" className="text-white">
                        Trang chủ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/san-pham" className="text-white">
                        Sản phẩm
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/ho-kinh-doanh" className="text-white">
                        Hộ kinh doanh
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/tin-tuc" className="text-white">
                        Tin tức
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="/thong-tin-dieu-hanh"
                        className="text-white"
                      >
                        Thông tin điều hành
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/lien-he" className="text-white">
                        Liên hệ
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
              <div className={`row search-container ${isOpen ? "nav-group-btn-open" : ""}`}>
                <div className="col-12">
                  <div
                    className="search-small-scr"
                    // style={{ maxWidth: "230px" }}
                  >
                    <InputGroup>
                      <Input
                        placeholder="Mã sản phẩm"
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="btn btn-inline btn-red-gradient"
                          onClick={onSearch}
                        >
                          <SearchIcon />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  contact: state.contact.contact,
});
const mapDispatchToProps = {
  getContact,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
