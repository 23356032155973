import {
  GET_ADS_LIST,
  GET_CONTACT,
  GET_USER_LIST,
  GET_USER_BY_ID,
  GET_MEDIA_LIST,
  GET_MEDIA_BY_ID,
  GET_NEWS_BY_ID,
  GET_NEWS_LIST,
  GET_OPINFO_BY_ID,
  GET_OPINFO_LIST,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILURE,
  GET_CERTIFICATE_SUCCESS,
  GET_DIARY,
  GET_DIARY_FAILURE,
  GET_DIARY_SUCCESS,
  GET_DISTRIBUTOR,
  GET_DISTRIBUTOR_FAILURE,
  GET_DISTRIBUTOR_SUCCESS,
  GET_MANUFACTURER_LIST,
  GET_MAP_LOCATION_SUCCESS,
  GET_OVERVIEW,
  GET_OVERVIEW_FAILURE,
  GET_OVERVIEW_SUCCESS,
  GET_PRODUCTS_BY_MANUFACTURER,
  GET_PRODUCTS_BY_MANUFACTURER_SUCCESS,
  GET_PRODUCTS_BY_MANUFACTURER_FAILURE,
  GET_MANUFACTURER_INFO,
  GET_MANUFACTURER_INFO_SUCCESS,
  GET_MANUFACTURER_INFO_FAILURE
} from "actions/types.js";
import {
  getNewsListSuccess,
  getNewsListFail,
  getNewsByIDSuccess,
  getNewsByIDFail,
  getOpInfoListSuccess,
  getOpInfoListFail,
  getOpInfoByIDSuccess,
  getOpInfoByIDFail,
} from "actions/news.js";
import {
  getMediaListSuccess,
  getMediaListFail,
  getMediaByIDSuccess,
  getMediaByIDFail,
} from "actions/media.js";
import {
  getUserListSuccess,
  getUserListFail,
  getUserByIDSuccess,
  getUserByIDFail,
} from "actions/user.js";
import {
  getManufacturerListSuccess,
  getManufacturerListFail,
} from "actions/manufacturer.js";
import {
  getContactSuccess,
  getContactFail,
} from "actions/contact.js";
import {
  getAdsListSuccess,
  getAdsListFail,
} from "actions/advertisement.js"
// import { Sample_GLN } from "assets/data.js";
import axios from "axios";
import { LINK_API } from "constants/API.js";
import cookie from "react-cookies";
import { call, put, takeLatest } from "redux-saga/effects";
import strings from "../constants/strings";

//Key google map api
const API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

//function check size of object
Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};
//Get overview
function* getOverview(scanID) {
  try {
    //Load ngôn ngữ từ Cookie
    let language = cookie.load("language");
    language = language ? language : "vi";
    strings.setLanguage(language);

    //Kiểm tra data mẫu
    var overview = ""; //Biến chứa dữ liệu phần overview
    var introData = ""; //Biến chứa dữ liệu phần giới thiệu sản phẩm
    let id = parseInt(scanID.id);
    id = scanID.id;
      const langChange = cookie.load("language");
      cookie.remove("language", { path: "/" });
      const { response, error } = langChange
        ? yield call(fetchOverview, scanID.id, langChange)
        : yield call(fetchLanguage, scanID.id);
      if (error) {
        yield put({
          type: GET_OVERVIEW_SUCCESS,
          intro: "Error",
        });
        return;
      }

      const language_fetch = langChange
        ? langChange
        : response.data.data && response.data.data.setting ? response.data.data.setting.defaultLanguage : "vi";
      if (langChange) {
        response.data.data.setting.defaultLanguage = langChange;
        //strings.setLanguage(langChange)
      } else {
        if (language_fetch) {
          strings.setLanguage(language_fetch);
        }
      }

      let checkNew = false;
      let checkGarden = false;

      if (response) {
        const type = response.data.data.type;
        overview = response.data.data;

        //Mã idCode show trên overview
        let idCode = ""; //Mã id show trên overview
        if (type !== "ALCOHOL") {
          if (id.length === 13) {
            idCode = response.data.data.mappingInfo.id;
          } else if (type === "OBJECT") {
            idCode = id;
          } else if (type === "BOX") {
            idCode = response.data.data.mappingInfo.id;
          } else {
            idCode = response.data.data.productInfo.gtin;
          }
        } else {
          idCode = response.data.data.objectInfo.idCode;
        }
        let titleID = "";
        let activation_date = "";
        if (type === "BOX") {
          activation_date = strings.packing_date;
          titleID = strings.ID_code_box;
        } else {
          if (type === "OBJECT") {
            activation_date = strings.registration_date;
            titleID = strings.ID_code_object;
          } else {
            activation_date = strings.activation_date;
            titleID = strings.ID_code;
          }
        }
        //Date and logo
        let listLogo = [];
        let date = "";
        if (type === "OBJECT") {
          const tempImage = response.data.data.objectInfo;
          if (tempImage.image) {
            if (tempImage.image.length !== 0) {
              if (tempImage.image.length > 1) {
                for (let x = 0; x < tempImage.image.length; x++) {
                  let itemsTemp = {
                    original: tempImage.image[x].url,
                    thumbnail: tempImage.image[x].url,
                    className: "imageSlider",
                  };
                  listLogo.push(itemsTemp);
                }
              } else {
                let itemsTemp = {
                  original: tempImage.image[0].url,
                  thumbnail: tempImage.image[0].url,
                  className: "imageSlider",
                };
                listLogo.push(itemsTemp);
              }
            }
          }
          date = response.data.data.objectInfo.registeredDate;
        } else {
          if (type !== "ALCOHOL") {
            const tempImage = response.data.data.productInfo;
            if (tempImage.image) {
              if (tempImage.image.length !== 0) {
                if (tempImage.image.length > 1) {
                  let splitLogo =
                    "https://res.cloudinary.com/dfdo92aru/image/upload";
                  for (let x = 0; x < tempImage.image.length; x++) {
                    let link_logo = tempImage.image[x].url;
                    let temp_link_logo = link_logo.split(splitLogo);
                    let result_link =
                      splitLogo + "/c_thumb,g_face,h_400" + temp_link_logo[1];
                    let thumbnail_link =
                      splitLogo + "/c_thumb,g_face,h_100" + temp_link_logo[1];
                    let itemsTemp = {
                      original: result_link,
                      thumbnail: thumbnail_link,
                      fullscreen: tempImage.image[x].url,
                      className: "imageSlider",
                    };
                    listLogo.push(itemsTemp);
                  }
                } else {
                  let splitLogo =
                    "https://res.cloudinary.com/dfdo92aru/image/upload";
                  let link_logo = tempImage.image[0].url;
                  let temp_link_logo = link_logo.split(splitLogo);
                  let result_link =
                    splitLogo + "/c_thumb,g_face,h_400" + temp_link_logo[1];
                  let itemsTemp = {
                    original: tempImage.image[0].url,
                    thumbnail: result_link,
                    className: "imageSlider",
                  };
                  listLogo.push(itemsTemp);
                }
              }
            }
            date = response.data.data.productInfo.registeredDate
              ? parseInt(response.data.data.productInfo.registeredDate)
              : response.data.data.objectInfo.registeredDate;
          } else {
            listLogo = introData.logo;
            date = introData.dateBegin;
          }
        }
        let Status = "";
        if (type !== "BOX") {
          if (type !== "OBJECT") {
            if (response.data.data.productInfo.status === "1") {
              Status = strings.sold;
            } else {
              Status = "";
            }
          } else {
            Status = ""; //Object chưa có trạng thái
          }
        } else {
          let tempCheck = response.data.data.boxInfo.status;
          if (tempCheck === "0") {
            Status = "Lưu kho";
          }
          if (tempCheck === "1") {
            Status = strings.sold;
          }
          if (tempCheck === "3") {
            Status = "Đang vận chuyển";
          }
          if (tempCheck === "4") {
            Status = "Đã đến nơi nhận";
          }
        }
        let infoDetail = "";
        let dynamicContent = "";
        if (checkNew) {
          if (type === "OBJECT") {
            const detailTemp_object = response.data.data.objectInfo;
            if (
              detailTemp_object.options &&
              detailTemp_object.options.length !== 0
            ) {
              infoDetail = detailTemp_object.options;
            }
            if (
              detailTemp_object.dynamicContent &&
              detailTemp_object.dynamicContent !== ""
            ) {
              dynamicContent = detailTemp_object.dynamicContent;
            }
          } else {
            const detailTemp_productInfo = response.data.data.productInfo;
            if (
              detailTemp_productInfo.options &&
              detailTemp_productInfo.options.length !== 0
            ) {
              infoDetail = detailTemp_productInfo.options;
            }
            if (
              detailTemp_productInfo.dynamicContent &&
              detailTemp_productInfo.dynamicContent !== ""
            ) {
              dynamicContent = detailTemp_productInfo.dynamicContent;
            }
          }
        } else {
          if (type === "BOX") {
            const detailTemp_object = response.data.data.productInfo;
            if (
              detailTemp_object.options &&
              detailTemp_object.options.length !== 0
            ) {
              infoDetail = detailTemp_object.options;
            } else {
              infoDetail = introData.overview;
            }

            if (
              detailTemp_object.dynamicContent &&
              detailTemp_object.dynamicContent !== ""
            ) {
              dynamicContent = detailTemp_object.dynamicContent;
            }
          } else {
            infoDetail = introData.overview;
          }
        }
        let checkGroupDetail = 0;
        if (typeof infoDetail === "object" && !Array.isArray(infoDetail)) {
          var size_sub = Object.size(infoDetail);
          if (size_sub === 0) {
            checkGroupDetail = 0;
          } else {
            checkGroupDetail = 1;
          }
        } else {
          if (Array.isArray(infoDetail)) {
            if (infoDetail.length === 0) {
              checkGroupDetail = 0;
            } else {
              if (Array.isArray(infoDetail[0].value)) {
                checkGroupDetail = 2;
              } else {
                checkGroupDetail = 3;
              }
            }
          }
        }
        let banner = "";
        
        if (
          response.data.data.productInfo.origin &&
          response.data.data.productInfo.origin.banner &&
          response.data.data.productInfo.origin.banner[0]
        ) {
          let link_banner =
            response.data.data.productInfo.origin.banner[0].secure_url;
          let splitBanner =
            "https://res.cloudinary.com/dfdo92aru/image/upload";
          let temp_link_banner = link_banner.split(splitBanner);
          banner = splitBanner + "/c_thumb,g_face,w_919" + temp_link_banner[1];
        } else {
          if (
            response.data.data.cooperativeInfo.banner &&
            response.data.data.cooperativeInfo.banner.secure_url
          ) {
            let link_banner =
              response.data.data.cooperativeInfo.banner.secure_url;

            let splitBanner =
              "https://res.cloudinary.com/dfdo92aru/image/upload";
            let temp_link_banner = link_banner.split(splitBanner);
            banner =
              splitBanner + "/c_thumb,g_face,w_919" + temp_link_banner[1];
          } else {
            banner = null;
          }
        }
        let expDate = "";
        if (response.data.data.productInfo.mfDate) {
          expDate = response.data.data.productInfo.mfDate;
        }

        let boxInfo = [];
        if (response.data.data.boxInfo) {
          let temp_box = response.data.data.boxInfo;
          if (temp_box.amount) {
            boxInfo.push({
              key: "Số lượng sản phẩm",
              value: temp_box.amount,
            });
          }
          if (temp_box.start) {
            boxInfo.push({
              key: "Tem bắt đầu",
              value: temp_box.start,
            });
          }
          if (temp_box.end) {
            boxInfo.push({
              key: "Tem kết thúc",
              value: temp_box.end,
            });
          }
        }
        
        yield put({
          type: GET_OVERVIEW_SUCCESS,
          data: overview,
          intro: introData,
          sample: false,
          new: checkNew,
          checkGarden: checkGarden,
          id: scanID.id,
          showId: idCode, //Mã show ra trong overview
          //gs1State: action.gs1State, // Kiểm tra trạng thái gs1 để hiển thị tra cứu
          detailInfo: infoDetail, //Thông tin chi tiết
          status: Status, //Trạng thái
          titleId: titleID,
          logo: listLogo,
          date: date,
          activation_date: activation_date,
          checkGroupDetail: checkGroupDetail,
          banner: banner,
          boxInfo: boxInfo,
          dynamicContent: dynamicContent,
          images360: overview.objectInfo.images360
            ? overview.objectInfo.images360
            : [],
          expDate: expDate,
          color: overview.setting && overview.setting.colorCode ? overview.setting.colorCode : '#2d98da'
        });
        //process data manufacturer
        const sample_data = response.data.data;
        let cooName = sample_data.cooperativeInfo.name;
        let cooDes = sample_data.cooperativeInfo.description
          ? sample_data.cooperativeInfo.description
          : "";
        let cooDynamicDescription = sample_data.cooperativeInfo
          .dynamicDescription
          ? sample_data.cooperativeInfo.dynamicDescription
          : "";
        let coo_ava = sample_data.cooperativeInfo.avatar
          ? sample_data.cooperativeInfo.avatar.length > 0
            ? sample_data.cooperativeInfo.avatar
            : []
          : [];
        let cooTx = sample_data.cooperativeInfo.tx
          ? sample_data.cooperativeInfo.tx
          : "";
        let cooMap = "";

        if (sample_data.setting && sample_data.setting.satelliteImagery && sample_data.cooperativeInfo.address) {
          cooMap =
            "https://www.google.com/maps/embed/v1/place?key=" +
            API_KEY +
            "&q=" +
            sample_data.cooperativeInfo.address +
            "&maptype=satellite";
        } else if(sample_data.cooperativeInfo.address){
          cooMap =
            "https://www.google.com/maps/embed/v1/place?key=" +
            API_KEY +
            "&q=" +
            sample_data.cooperativeInfo.address;
        } else if (sample_data.objectInfo && sample_data.objectInfo.zoneAddress && sample_data.objectInfo.zoneAddress.city) {
          cooMap =
          "https://www.google.com/maps/embed/v1/place?key=" +
          API_KEY +
          "&q=" +
          sample_data.objectInfo.zoneAddress.city;
        }

        if(sample_data.cooperativeInfo.address){
          let mapLocation = yield fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${sample_data.cooperativeInfo.address}&key=AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528`
          );
          mapLocation = yield mapLocation.json();
          if (mapLocation.results[0]) {
            if (mapLocation.results[0].geometry) {
              if (mapLocation.results[0].geometry.location) {
                yield put({
                  type: GET_MAP_LOCATION_SUCCESS,
                  data: mapLocation.results[0].geometry.location,
                });
              }
            }
          }
        }
        
        let cooImage = [];
        for (let j = 0; j < coo_ava.length; j++) {
          cooImage.push({
            src: coo_ava[j].url,
            thumbnail: coo_ava[j].url,
            thumbnailHeight: 2,
            caption: cooName,
          });
        }

        
        let coo_Cert = sample_data.cooperativeInfo.certificates
          ? sample_data.cooperativeInfo.certificates.length > 0
            ? sample_data.cooperativeInfo.certificates
            : []
          : [];
        let cooCert = [];
        for (let j = 0; j < coo_Cert.length; j++) {
          cooCert.push({
            src: coo_Cert[j].url,
            thumbnail: coo_Cert[j].url,
            thumbnailHeight: 2,
            caption: cooName + " - certificate",
          });
        }
        
        let cooInfo = [];
        if (sample_data.cooperativeInfo.gln) {
          cooInfo.push({
            key: strings.enterprise_code,
            value: sample_data.cooperativeInfo.gln,
          });
        }
        if (sample_data.cooperativeInfo.phone) {
          cooInfo.push({
            key: strings.phone,
            value: sample_data.cooperativeInfo.phone,
          });
        }
        if (sample_data.cooperativeInfo.email) {
          cooInfo.push({
            key: strings.email,
            value: sample_data.cooperativeInfo.email,
          });
        }
        if (sample_data.cooperativeInfo.website) {
          cooInfo.push({
            key: strings.website,
            value: sample_data.cooperativeInfo.website,
          });
        }
        if (sample_data.cooperativeInfo.address) {
          cooInfo.push({
            key: strings.address,
            value: sample_data.cooperativeInfo.address,
          });
        }
        if (sample_data.objectInfo.zoneAddress) {
          cooInfo.push({
            key: strings.address_area,
            value: sample_data.objectInfo.zoneAddress.city,
          });
        }

        yield put({
          type: "GET_MANUFACTURER_SUCCESS",
          cooName: cooName,
          cooInfo: cooInfo,
          cooDes: cooDes,
          cooImage: cooImage,
          cooCert: cooCert,
          cooMap: cooMap,
          cooTx: cooTx,
          cooDynamicDescription: cooDynamicDescription,
        });

        //proccess Data diary
        //Get diary
        const diary = yield call(fetchDiary, scanID.id, language_fetch);
        const data_diary = diary.data;

        let stories = [];
        let trackingMap = [];
        let video = introData.video
          ? introData.video
          : sample_data.cooperativeInfo.video
          ? sample_data.cooperativeInfo.video
          : "";
        const list_stories = data_diary.data.diary;
        // console.log(list_stories[0][0][0].step)
        
        for (let j = 0; j < list_stories.length; j++) {
          stories[j] = [];
          trackingMap[j] = [];
          const list_step = list_stories[j];
          for (let z = 0; z < list_step.length; z++) {
            let defaultImagesStep = [];
            if (list_step[z][0] && list_step[z][0].action_image) {
              let tempDefaultImage = list_step[z][0].action_image;
              for (let b = 0; b < list_step[z][0].action_image.length; b++) {
                let splitDiary =
                  "https://res.cloudinary.com/dfdo92aru/image/upload";
                let link_diary = tempDefaultImage[b].secure_url;
                let temp_link_diary = link_diary.split(splitDiary);
                let result_link =
                  splitDiary + "/c_thumb,g_face,h_70" + temp_link_diary[1];
                defaultImagesStep.push({
                  src: tempDefaultImage[b].secure_url,
                  thumbnail: result_link,
                  thumbnailHeight: 70,
                  thumbnailWidth: 100,
                  caption: "Agridential",
                });
              }
            }
            
            if (list_step[z][0]) {
              stories[j].push({
                inverted: true,
                badgeColor: "success",
                title: list_step[z][0].step,
                titleColor: "success",
                name: list_step[z][0].step,
                description: list_step[z][0].step_desc,
                badgeIcon: "agri.svg", //icon badge
                firstElement: {},
                listElement: [],
                defaultImage: defaultImagesStep,
              });
            }
            
            if (list_step[z] && list_step[z].length) {
              const list_info = list_step[z].reverse();
              for (let m = 0; m < list_info.length; m++) {
                let images_temp = [];
                let default_images = [];
                let certificateDetail = [];
                if (list_info[m].moreInfo) {
                  if (list_info[m].moreInfo.gps) {
                    if (list_info[m].moreInfo.gps.coords) {
                      list_info[m].moreInfo.gps.coords.from_name =
                        list_info[m].from_name;
                      trackingMap[j].push(list_info[m].moreInfo.gps.coords);
                    }
                  }
                  if (list_info[m].moreInfo.certificates) {
                    certificateDetail = list_info[m].moreInfo.certificates;
                  }
                }
                if (list_info[m].images) {
                  for (let k = 0; k < list_info[m].images.length; k++) {
                    images_temp.push({
                      src: list_info[m].images[k].url,
                      thumbnail: list_info[m].images[k].url,
                      thumbnailHeight: 1,
                      caption: "",
                    });
                  }
                }
                if (list_info[m] && list_info[m].action_image) {
                  for (let k = 0; k < list_info[m].action_image.length; k++) {
                    default_images.push({
                      src: list_info[m].action_image[k].url,
                      thumbnail: list_info[m].action_image[k].url,
                      thumbnailHeight: 1,
                      caption: "",
                    });
                  }
                }
                if (m === 0 && stories[j][z]) {
                  stories[j][z].firstElement = {
                    who: list_info[m].from_name,
                    when: parseInt(list_info[m].date),
                    detail: list_info[m].step_attribute,
                    tx: list_info[m].tx,
                    step_attribute: list_info[m].step_attribute,
                    defaultImage: default_images, //ảnh mặc định
                    image: images_temp, // ảnh người dùng thêm,
                    certificates: certificateDetail, //link file chứng chỉ
                  };
                } else if (stories[j][z]) {
                  stories[j][z].listElement.push({
                    who: list_info[m].from_name,
                    when: parseInt(list_info[m].date),
                    detail: list_info[m].step_attribute,
                    tx: list_info[m].tx,
                    step_attribute: list_info[m].step_attribute,
                    defaultImage: default_images, //ảnh mặc định
                    image: images_temp, //ảnh người dùng thêm
                    certificates: certificateDetail, //link file chứng chỉ
                  });
                }
              }
            }
          }
        }
        const totalPage = stories.length;
        let pages = [
          {
            text: "Mùa vụ",
            active: false,
          },
        ];
        for (let j = 0; j < totalPage; j++) {
          if (j === 0) {
            pages.push({
              text: j + 1,
              active: true,
            });
          } else {
            pages.push({
              text: j + 1,
              active: false,
            });
          }
        }

        yield put({
          type: GET_DIARY_SUCCESS,
          stories: stories,
          video: video,
          totalPage: totalPage,
          pages: pages,
          tracking: trackingMap,
          checkReverse: sample_data.setting && sample_data.setting.reverseListDiaries
            ? sample_data.setting.reverseListDiaries
            : false,
        });

        const fetchDataDistributor = yield call(
          fetchDistributor,
          scanID.id,
          language_fetch
        );
        const data_distributor =
          fetchDataDistributor.data.data.distributors.length > 0
            ? fetchDataDistributor.data.data.distributors[0].distributor
            : [];

        let distributor_list = [];
        if (data_distributor.length > 0) {
          distributor_list.push({
            main: [],
            name: data_distributor[0].name,
            description: data_distributor[0].description,
            branchs: data_distributor[0].branchs,
          });
          Object.keys(data_distributor[0]).map((key, index) => {
            if (
              key !== "branchs" &&
              key !== "_id" &&
              key !== "uuid" &&
              key !== "name" &&
              key !== "description"
            )
              if (key === "address") {
                distributor_list[0].main.push({
                  key: strings.address,
                  value: data_distributor[0][key],
                });
              }
            if (key === "email") {
              distributor_list[0].main.push({
                key: strings.email,
                value: data_distributor[0][key],
              });
            }
            if (key === "phone") {
              distributor_list[0].main.push({
                key: strings.phone,
                value: data_distributor[0][key],
              });
            }
            if (key === "website") {
              distributor_list[0].main.push({
                key: strings.website,
                value: data_distributor[0][key],
              });
            }
            return key;
          });
        }
        if (distributor_list.length > 0) {
          yield put({
            type: GET_DISTRIBUTOR_SUCCESS,
            data: distributor_list,
            check: true,
          });
        } else {
          yield put({
            type: GET_DISTRIBUTOR_SUCCESS,
            data: distributor_list,
            check: false,
          });
        }

        let certificates = [];
        let fullSize_Certificates = [];
        if (type === "ALCOHOL") {
          certificates = introData.certificate;
          yield put({
            type: GET_CERTIFICATE_SUCCESS,
            data: certificates,
            slider: false,
          });
        } else {
          const splitString =
            "https://res.cloudinary.com/dfdo92aru/image/upload";
          if (type !== "OBJECT") {
            if (overview.productInfo.certificates) {
              let tempCertificates = overview.productInfo.certificates;
              for (let z = 0; z < tempCertificates.length; z++) {
                let link = tempCertificates[z].url;
                let temp_link = link.split(splitString);
                let result_link =
                  splitString + "/c_thumb,g_face,h_90" + temp_link[1];
                fullSize_Certificates.push(tempCertificates[z].url);
                certificates.push(result_link);
              }
              yield put({
                type: GET_CERTIFICATE_SUCCESS,
                data: certificates,
                dataFull: fullSize_Certificates,
                slider: true,
              });
            } else {
              yield put({
                type: GET_CERTIFICATE_SUCCESS,
                data: certificates,
                dataFull: fullSize_Certificates,
                slider: false,
              });
            }
          }
        }
      } else {
        yield put({
          type: GET_OVERVIEW_SUCCESS,
          data: "",
          intro: null,
          sample: false,
          new: false,
          checkGarden: false,
          id: scanID.id,
          color: overview.setting && overview.setting.colorCode ? overview.setting.colorCode : '#2d98da',
        });
      }
  } catch (error) {
    yield put({ type: GET_OVERVIEW_FAILURE, payload: "" });
  }
}
function fetchLanguage(id) {
  return axios({
    method: "GET",
    url: LINK_API.GET_TREE_NEW + "/baseinfo?gln=" + id + "&nestedgroup=true",
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function fetchOverview(id, language) {
  return axios({
    method: "GET",
    url:
      LINK_API.GET_TREE_NEW +
      "/baseinfo?gln=" +
      id +
      "&lg=" +
      language +
      "&nestedgroup=true",
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

//get diary
function* getDiary(scanID) {
  try {
    const response = yield call(fetchDiary, scanID.id);
    const data = response.data;
    yield put({
      type: GET_DIARY_SUCCESS,
      data: data.data,
      errorCode: data.errorCode,
    });
  } catch (error) {
    yield put({ type: GET_DIARY_FAILURE, payload: "", errorCode: 0 });
  }
}
function fetchDiary(id, language) {
  return axios({
    method: "GET",
    url:
      LINK_API.GET_TREE_NEW +
      "?gln=" +
      id +
      "&lg=" +
      language +
      "&nestedgroup=true",
  });
}

//get Distributor
function* getDistributor(data) {
  try {
    const response = yield call(fetchDistributor, data.id);
    const data_fetch = response.data;
    yield put({
      type: GET_DISTRIBUTOR_SUCCESS,
      data: data_fetch.data,
      errorCode: data_fetch.errorCode,
    });
  } catch (error) {
    yield put({ type: GET_DISTRIBUTOR_FAILURE, payload: "", errorCode: 0 });
  }
}
function fetchDistributor(id, language) {
  return axios({
    method: "GET",
    url: LINK_API.GET_DISTRIBUTOR + "?gln=" + id + "&lg=" + language,
  });
}

//fetch productList
function* getProductList() {
  try {
    const response = yield call(fetchList);
    const data = response.data;
    yield put({
      type: GET_PRODUCT_LIST_SUCCESS,
      data: data.data,
      errorCode: data.errorCode,
    });
  } catch (error) {
    yield put({ type: GET_PRODUCT_LIST_FAILURE, payload: "", errorCode: 0 });
  }
}
function fetchList() {
  return axios({
    method: "GET",
    url: LINK_API.GET_PRODUCT_LIST,
  });
}

//fetch News List
const fetchManufacturerList = (params) => {
  return axios.get(LINK_API.GET_MANUFACTURER_LIST, { params });
};

function* handleFetchManufacturerList(action) {
  const { payload: param } = action;
  
  try {
    const response = yield call(fetchManufacturerList, param);
    const data = response.data.data;
    yield put(getManufacturerListSuccess(data));
  } catch (error) {
    yield put(getManufacturerListFail(error));
  }
}

//fetch News List
const fetchNewsList = (params) => {
  return axios.get(LINK_API.GET_NEWS_LIST, { params });
};

function* handleFetchNewsList(action) {
  const { payload: param } = action;
  
  try {
    const response = yield call(fetchNewsList, param);
    const data = response.data.data;
    yield put(getNewsListSuccess(data));
  } catch (error) {
    yield put(getNewsListFail(error));
  }
}

//fetch Operation Info List
const fetchOpInfoList = (params) => {
  return axios.get(LINK_API.GET_OPINFO_LIST, { params });
};

function* handleFetchOpInfoList(action) {
  const { payload: param } = action;
  
  try {
    const response = yield call(fetchOpInfoList, param);
    const data = response.data.data;
    yield put(getOpInfoListSuccess(data));
  } catch (error) {
    yield put(getOpInfoListFail(error));
  }
}

//fetch News By ID
const fetchNewsByID = (params) => {
  return axios.get(`${LINK_API.GET_NEWS_LIST}/${params.id}`);
};

function* handleFetchNewsByID(action) {
  const { payload: param } = action;
  try {
    const response = yield call(fetchNewsByID, param);
    const data = response.data.data;
    yield put(getNewsByIDSuccess(data));
  } catch (error) {
    yield put(getNewsByIDFail(error));
  }
}

//fetch Operation Info By ID
const fetchOpInfoByID = (params) => {
  return axios.get(`${LINK_API.GET_OPINFO_LIST}/${params.id}`);
};

function* handleFetchOpInfoByID(action) {
  const { payload: param } = action;
  try {
    const response = yield call(fetchOpInfoByID, param);
    const data = response.data.data;
    yield put(getOpInfoByIDSuccess(data));
  } catch (error) {
    yield put(getOpInfoByIDFail(error));
  }
}

//fetch Media List
const fetchMediaList = (params) => {
  return axios.get(LINK_API.GET_MEDIA_LIST, { params });
};

function* handleFetchMediaList(action) {
  const { payload: param } = action;
  
  try {
    const response = yield call(fetchMediaList, param);
    const data = response.data.data;
    yield put(getMediaListSuccess(data));
  } catch (error) {
    yield put(getMediaListFail(error));
  }
}

//fetch Media By ID
const fetchMediaByID = (params) => {
  return axios.get(`${LINK_API.GET_MEDIA_LIST}/${params.id}`);
};

function* handleFetchMediaByID(action) {
  const { payload: param } = action;
  try {
    const response = yield call(fetchMediaByID, param);
    const data = response.data.data;
    yield put(getMediaByIDSuccess(data));
  } catch (error) {
    yield put(getMediaByIDFail(error));
  }
}

//fetch User List
const fetchUserList = (params) => {
  return axios.get(LINK_API.GET_USER_LIST, { params });
};

function* handleFetchUserList(action) {
  const { payload: param } = action;
  
  try {
    const response = yield call(fetchUserList, param);
    const data = response.data.data;
    yield put(getUserListSuccess(data));
  } catch (error) {
    yield put(getUserListFail(error));
  }
}

//fetch User By ID
const fetchUserByID = (params) => {
  return axios.get(`${LINK_API.GET_USER_LIST}/${params.id}`);
};

function* handleFetchUserByID(action) {
  const { payload: param } = action;
  try {
    const response = yield call(fetchUserByID, param);
    const data = response.data.data;
    yield put(getUserByIDSuccess(data));
  } catch (error) {
    yield put(getUserByIDFail(error));
  }
}

//fetch Contact
const fetchContact = (params) => {
  return axios.get(LINK_API.GET_CONTACT);
};

function* handleFetchContact(action) {
  try {
    const response = yield call(fetchContact);
    const data = response.data.data;
    yield put(getContactSuccess(data));
  } catch (error) {
    yield put(getContactFail(error));
  }
}

// fetch Advertisement
const fetchAdvertisement = (params) => {
  return axios.get(LINK_API.GET_ADS_LIST);
};

function* handleFetchAdvertisement(action) {
  try {
    const response = yield call(fetchAdvertisement);
    const data = response.data.data;
    yield put(getAdsListSuccess(data));
  } catch (error) {
    yield put(getAdsListFail(error));
  }
}

// fetch Products By Manufacturer
const fetchProductsByManufacturer = (params) => {
  return axios.get(`${LINK_API.GET_PRODUCTS_BY_MANUFACTURER}?uuid=${params.uuid}`);
};

function* handleFetchProductsByManufacturer(action) {
  const { params } = action;
  try {
  const response = yield call(fetchProductsByManufacturer, params);
    const data = response.data;
    yield put({
      type: GET_PRODUCTS_BY_MANUFACTURER_SUCCESS,
      data: data.data,
      errorCode: data.errorCode,
    });
  } catch (error) {
    yield put({ type: GET_PRODUCTS_BY_MANUFACTURER_FAILURE, error, errorCode: 0 });
  }
}

// fetch Products By Manufacturer
const fetchManufacturerInfo = (params) => {
  return axios.get(`${LINK_API.GET_MANUFACTURER_INFO}?uuid=${params.uuid}`);
};

function* handleFetchManufacturerInfo(action) {
  const { params } = action;
  try {
  const response = yield call(fetchManufacturerInfo, params);
    const data = response.data;
    yield put({
      type: GET_MANUFACTURER_INFO_SUCCESS,
      data: data.data,
      errorCode: data.errorCode,
    });
  } catch (error) {
    yield put({ type: GET_MANUFACTURER_INFO_FAILURE, error, errorCode: 0 });
  }
}
//root saga
export default function* rootSaga() {
  yield takeLatest(GET_OVERVIEW, getOverview);
  yield takeLatest(GET_DIARY, getDiary);
  yield takeLatest(GET_DISTRIBUTOR, getDistributor);
  yield takeLatest(GET_PRODUCT_LIST, getProductList);
  yield takeLatest(GET_MANUFACTURER_LIST, handleFetchManufacturerList);
  yield takeLatest(GET_NEWS_LIST, handleFetchNewsList);
  yield takeLatest(GET_NEWS_BY_ID, handleFetchNewsByID);
  yield takeLatest(GET_MEDIA_LIST, handleFetchMediaList);
  yield takeLatest(GET_MEDIA_BY_ID, handleFetchMediaByID);
  yield takeLatest(GET_USER_LIST, handleFetchUserList);
  yield takeLatest(GET_USER_BY_ID, handleFetchUserByID);
  yield takeLatest(GET_OPINFO_BY_ID, handleFetchOpInfoByID);
  yield takeLatest(GET_OPINFO_LIST, handleFetchOpInfoList);
  yield takeLatest(GET_CONTACT, handleFetchContact);
  yield takeLatest(GET_ADS_LIST, handleFetchAdvertisement);
  yield takeLatest(GET_PRODUCTS_BY_MANUFACTURER ,handleFetchProductsByManufacturer);
  yield takeLatest(GET_MANUFACTURER_INFO ,handleFetchManufacturerInfo);
}