import LocalizedStrings from "localized-strings";
// import VN from "assets/img/flags/VN.png";
// import EN from "assets/img/flags/GB.png";

const strings = new LocalizedStrings({
  en: {
   // icon: EN,
    unit: "Unit",
    traceability_solution: "Traceability solution",
    feedbackemo: "Evaluate",
    solution_features: "Application of GS1-compliant Blockchain technology",
    product_name: "Product name",
    sample_product: "Sample product information",
    product_gtin: "Product code - GTIN",
    box_gtin: "Box code",
    expiried_date: "Expiry date",
    preservation: "Preservation",
    guide: "HDSD",
    sold: "Sold",
    unsold: "Unsold",
    detail: "Detail",
    collapse: "Collapse",
    product_info: "Product information",
    tree_info: "Tree information",
    farm_info: "Garden information",
    cooporative_info: "Cooperative information",
    origin: "Origin",
    journey: "Journey",
    certificate: "Certificate",
    product_origin: "Origin of the product",
    product_journey: "Product's journey",
    product_certificate: "Certification information",
    tree_code: "Tree code (GS1)",
    lookup_tree: "Crops searching",
    farm: "Garden",
    farm_code: "Garden code (GS1)",
    scale: "Scale",
    blockchain_info: "Blockchain information",
    cooporative: "Cooperative",
    enterprise: "Enterprise",
    enterprise_code: "Enterprise code (GS1)",
    cooporative_code: "Cooperative code (GS1)",
    phone: "Phone",
    actions: "Actions",
    email: "Email",
    website: "Website",
    address: "Address",
    view_detail: "See all actions",
    view_detail_overview: "See details",
    blockchain_evidence: "Blockchain evidence",
    activation_date: "Activation date",
    packing_date: "Date of packing",
    registration_date: "Registration Date",
    introduction_product: "Introduction",
    productGS1_gtin: "Business Code (GS1)",
    feedback: "Feedback",
    production_process: "Production process",
    search: "Search",
    tracerbility: "Traceability Solution",
    tracerbility1: "Applying Blockchain-compliant with GS1 standard",
    homepage: "Homepage",
    search_product: "Product lookup",
    food_selfty: "Certificate of Food Safety",
    vbc_address:
      "4th floor, Lu Gia Plaza, 70 Lu Gia, Ward 15, District 11, Ho Chi Minh City",
    vbc_about:
      "Vietnam Blockchain Corporation-VBC is a company providing solutions and services on blockchain technology to serve key areas such as Agriculture, Manufacturing, Supply Chain, Logistics, E-Commerce, Economic Sharing, Fintech, Public Services and Smart City.",
    agri_about:
      "Agridential.vn is a traceability solution that applies Blockchain technology for the value chain.",
    tracerbility_journey: "Product Journey",

    product_feedback: "Product feedback",
    product_feedback_description:
      "We need your feedback about our product, thank you!",
    product_feedback_description_Education:
      "We need your feedback about our profile, thank you!",
    product_feedback_content: "Feedback content",
    product_feedback_content_more: "Additional feedbacks",
    send: "Send",
    sour: "Sour",
    medium: "Medium",
    sweet: "Sweet",
    out_of_date: "Expired",
    tree_species: "Tree's species",
    tree_age: "Tree's age",
    tree_size: "Tree's size",
    tree_quantity: "Quantity",
    tree_description: "Details",
    rent: "Available",
    rented: "Not available",
    diary: "Diary",
    produce_diary: "Manufacturing diary",
    production_standard: "Production Standard",

    applied_blockchain: "Application of Blockchain technology",
    accordance_with_gs1_standard: "GS1-compliant",
    close: "Close",
    cancel: "Cancel",
    rent_content:
      "Please leave your phone number, the cooperative will contact you soon!",
    input_lookup_code: "Type/Scan product code",
    garlic_coo: "Enterprise",
    biophap_tutorial: "Tutorial",

    caffe_species: "Type of coffee",
    caffe_species_1: "Bean/Powder",
    caffe_expired: "Expiried date",
    caffe_weight: "Net weight",
    caffe_info: "Characteristics",
    caffe_export: "Ingredient",
    caffe_journey: "Production process",
    caffe_mix: "Mixed",
    caffe_process: "Processing method",
    caffe_process_rang: "Roasting method",
    caffe_info_1: "Ferment twice",
    caffe_process_rang_1: "Rustic roast - Raw 7",
    caffe_expired_1:
      " 12 months. Best flavor and aroma in 2 weeks when open the package",
    caffe_journey_1: "100% natural",
    caffe_intro:
      "Mayaca Good Inside (GI) is the core product of Mayaca Coffee with twice-fermentation technology in a special coffee flavor and preserves the value of coffee beans.",
    caffe_size: "Size",
    caffe_flavor: " Flavor, aroma",
    caffe_flavor_1:
      "Good flavorfull with a litte quinic acid bright, fruity, honey sweet, dark chocolate, medium cafein, last after taste",
    caffe_usefor: "Use for",
    caffe_usefor_1:
      "Coffee machine or grind beans into powder for filter, drip, shyphon,...",
    caffe_preservation:
      "Preserved in a cool dry place, avoid direct sunlight MaYaCa pure coffee products are best for your health and your family!",

    honey_protect: "Preservation",

    feedback_failed: "Failed",
    feedback_success: "Success",
    feedback_processing: "Processing",
    description: "Description",
    image: "Business image",
    who: "Who",
    when: "When",
    details: "Details",
    proof: "Blockchain Proof",
    images: "Images",
    tab_xx: "FROM",
    tab_ng: "ORIGIN",
    tab_xx_edu: "PERSONAL",
    tab_ng_edu: "Credential",
    tab_pp: "Distribution",
    tab_cc: "Certificate",
    ID_code: "Trace ID",
    updated: "The information is being updated",
    noDiary: "The information is being updated",
    noInfo: "The information is being updated",
    noteUser: "The above information is declared by users during production",
    available: "Available",
    status: "Status",
    map_trace: "Traceability map",
    havecertificate: "The information is being updated",
    view_detail_1: "See more",
    see_diff_info: "See other information",
    headquarters: "HEADQUARTERS",
    ID_GS1: "GS1 Code",
    ID_code_box: "Box lookup code",
    ID_code_object: "Object lookup code",

    branch: "BRANCHS",
    address_area: "Address of production area",
    Image360: "360 Mode",

    genProduct: "Authentic product",
    expDate: "Date of manufacture",
    certQuality: "Quality certification",
    traceID: "Trace ID",
    diaryFirst: "Traceability diary has been being updated by",
    diarySecond: "and protected by Blockchain technology"
  },
  vi: {
    enterprise_code: "Mã doanh nghiệp (GS1)",
   // icon: VN,
    feedbackemo:"Đánh giá",
    traceability_solution: "Giải pháp truy xuất nguồn gốc",
    solution_features: "Áp dụng công nghệ Blockchain phù hợp tiêu chuẩn GS1",
    product_name: "Tên sản phẩm",
    sample_product: "Thông tin sản phẩm mẫu",
    product_gtin: "Mã số sản phẩm",
    box_gtin: "Mã thùng",
    productGS1_gtin: "Mã GS1 của sản phẩm - GS1",
    expiried_date: "Hạn sử dụng",
    preservation: "Bảo quản",
    guide: "Hướng dẫn sử dụng",
    sold: "Đã bán",
    weight: "Khối lượng tịnh",
    characteristic: "Đặc điểm",
    unsold: "Chưa bán",
    detail: "Chi tiết",
    collapse: "Thu gọn",
    production_standard: "Tiêu chuẩn sản xuất",
    product_info: "Thông tin sản phẩm",
    tree_info: "Thông tin cây trồng",
    farm_info: "Thông tin nông hộ",
    cooporative_info: "Thông tin hợp tác xã",
    origin: "Xuất xứ",
    journey: "Nguồn gốc",
    ingredient: "Thành phần",
    certificate: "Chứng nhận doanh nghiệp",
    product_origin: "Xuất xứ sản phẩm",
    product_journey: "Truy xuất nguồn gốc sản phẩm",
    product_certificate: "Thông tin chứng nhận",
    tree_code: "Mã cây (GS1)",
    Image360: "Ảnh 360",
    genProduct: "Sản phẩm chính hãng",
    lookup_tree: "Tra cứu cây trồng",
    farm: "Nông hộ",
    farm_code: "Mã NH (GS1)",
    scale: "Quy mô",
    blockchain_info: "Thông tin Blockchain",
    cooporative: "Hợp tác xã",
    cooporative_code: "Mã DN (GS1)",
    cooporative_code_gln: "Mã DN",
    enterprise: "Doanh nghiệp",
    phone: "Số điện thoại",
    tracerbility_journey: "Truy xuất nguồn gốc",
    email: "Email",
    website: "Website",
    address: "Địa chỉ",
    actions: "Hoạt động",
    view_detail: "Xem tất cả các hoạt động",
    view_detail_overview: "Xem chi tiết",
    blockchain_evidence: "Xác thực trên nền tảng Blockchain",
    activation_date: "Ngày kích hoạt",
    packing_date: "Ngày đóng thùng",
    registration_date:"Ngày đăng ký",
    introduction_product: "Giới thiệu",
    feedback: "Góp ý",
    production_process: "Quá trình sản xuất",
    search: "Tra cứu",
    tracerbility: "Giải pháp truy xuất nguồn gốc",
    tracerbility1: "Áp dụng công nghệ Blockchain phù hợp tiêu chuẩn GS1",
    homepage: "Trang chủ",
    food_selfty: "Chứng nhận vệ sinh an toàn thực phẩm",
    search_product: "Tra cứu sản phẩm",
    product_feedback: "Đánh giá sản phẩm",
    product_feedback_description:
      "Quý khách hàng nếu hài lòng hoặc có góp ý về sản phẩm vui lòng điền thông tin và gửi tới hợp tác xã",
    product_feedback_description_Education:
      "Chúng tôi rất vui lòng khi nhận được góp ý của bạn, cảm ơn!",
    product_feedback_content: "Nội dung phản hồi",
    product_feedback_content_more: "Góp ý thêm",
    send: "Xác nhận",
    sour: "Chua",
    medium: "Vừa",
    sweet: "Ngọt",
    out_of_date: "Quá hạn",
    vbc_address:
      "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, Tp.HCM",
    vbc_about:
      "Công ty Cổ phần Vietnam Blockchain (Vietnam Blockchain Corporation - VBC) là công ty cung cấp các giải pháp và dịch vụ về công nghệ blockchain phục vụ các lĩnh vực chủ chốt như Nông nghiệp, Sản xuất, Chuỗi cung ứng, Hậu cần, Thương mại điện tử, Kinh tế chia sẻ, Công nghệ tài chính, Dịch vụ công và Đô thị thông minh.",
    agri_about:
      "Agridential.vn là giải pháp truy xuất nguồn gốc áp dụng công nghệ Blockchain theo chuỗi giá trị. Giải pháp được nghiên cứu và phát triển bởi công ty CP Vietnam Blockchain.",

    tree_species: "Giống",
    tree_age: "Tuổi cây",
    tree_size: "Kích thước",
    tree_quantity: "Sản lượng",
    tree_description: "Lý lịch cây",
    rice_species: "Loại gạo",
    rice_expired: "Hạn sử dụng",
    rice_weight: "Khối lượng tịnh",
    rice_info: "Đặc điểm",
    rice_export: "Thành phần",
    rice_journey: "Quy trình sản xuất",

    garlic_expired: "Hạn sử dụng",
    garlic_tutorial: "Hướng dẫn sử dụng",
    garlic_taste: "Mùi vị",
    garlic_health: "Thành phần dinh dưỡng",
    garlic_coo: " Doanh nghiệp",

    caffe_species: "Loại cà phê",
    caffe_species_1: "MaYaCa GOOD INSIDE GI",
    caffe_expired: "Hạn sử dụng",
    caffe_weight: "Khối lượng tịnh",
    caffe_info: "Đặc điểm",
    caffe_export: "Thành phần",
    caffe_journey: "Quy trình sản xuất",
    caffe_mix: "Tạp lẫn",
    caffe_process: "Phương pháp sơ chế",
    caffe_process_rang: "Phương pháp rang",
    caffe_info_1: "Lên men 2 lần",
    caffe_process_rang_1: "Rang mộc - không tẩm",
    caffe_expired_1:
      "12 tháng, có hương vị và mùi thơm ngon nhất trong 2 tuần đầu sau khi mở gói. ",
    caffe_journey_1: "100% thiên nhiên",
    caffe_intro:
      "Mayaca Good Inside(GI) là sản phẩm tâm huyết chủ lực của Mayaca Coffee với công nghệ lên men 2 lần trong trái cho vị cà phê đặc biệt và giữ nguyên giá trị của hạt cà phê.",
    caffe_size: "Kích thước",
    caffe_flavor: "Hương vị, mùi thơm",
    caffe_flavor_1:
      "Hương vị sẽ tuyệt vời nếu kết hợp với một ít quinic acid bright, trái cây, mật ong và chocolate đắng, cafein vừa ",
    caffe_usefor: "Dùng cho",
    caffe_usefor_1:
      "Dùng cho máy pha cà phê, hoặc xay thành bột để lọc, pha phin hoặc syphon",
    caffe_preservation:
      "Bảo quản ở nơi thoáng mát, tránh ánh nắng trực tiếp. Các sản phẩm cà phê MaYaCa nguyên chất là tốt nhất cho sức khỏe của bạn và gia đình",

    honey_protect: "Bảo quản",
    honey_weight: "Thể tích cực",

    incense_weight: "Trọng lượng",
    incense_part: "Thành phần",
    incense_benefit: "Công dụng",
    incense_package: "Bao bì",
    incense_certificate: "Sản phẩm đạt chuẩn",

    biophap_weight: "Trọng lượng",
    biophap_tutorial: "Hướng dẫn sử dụng",
    biophap_type: "Tiêu chuẩn sản xuất",

    rent: "Đặt thuê",
    rented: "Đã thuê",
    diary: "Nhật ký",
    produce_diary: "Nhật ký chăm sóc",
    coffee_diary: "Truy xuất nguồn gốc",
    fruit_diary: "Truy xuất nguồn gốc",
    rice_diary: "Truy xuất nguồn gốc",
    video_coffee_diary: "Quá trình sản xuất",
    applied_blockchain: "Áp dụng công nghệ Blockchain",
    accordance_with_gs1_standard: "Phù hợp tiêu chuẩn GS1",
    close: "Đóng",
    cancel: "Hủy",
    rent_content:
      "Vui lòng để lại số điện thoại, hợp tác xã sẽ liên hệ lại với bạn!",
    input_lookup_code: "Nhập/Quét mã sản phẩm",
    input_lookup_code_cert: "Nhập/Quét mã",

    certificate_tab_center: "Trung tâm",
    certificate_tab_process: "Thông tin",

    feedback_failed: "Không thành công",
    feedback_success: "Thành công",
    feedback_processing: "Đang xử lý",
    description: "Mô tả",
    image: "Hình ảnh doanh nghiệp",
    who: "Người thực hiện",
    when: "Thời gian",
    details: "Chi tiết",
    proof: "Xác thực trên nền tảng Blockchain",
    images: "Hình ảnh",
    tab_xx: "XUẤT XỨ",
    tab_ng: "Nguồn gốc",
    tab_xx_edu: "Cá nhân",
    tab_ng_edu: "Chứng nhận",
    tab_pp: "Phân phối",
    tab_cc: "CHỨNG NHẬN",
    ID_code: "Mã tra cứu",
    ID_code_box: "Mã tra cứu lô hàng",
    ID_code_object: "Mã sản phẩm",
    updated: "Thông tin đang được cập nhật",
    noDiary: "Thông tin đang được cập nhật",
    noInfo: "Thông tin đang được cập nhật",
    noteUser: "Những thông tin trên do người dùng khai báo trong quá trình sản xuất",
    available: "Chưa bán",
    status: "Trạng thái",
    map_trace: "Bản đồ truy xuất",
    havecertificate: "Chưa có thông tin các chứng nhận",
    view_detail_1: "Xem thêm",
    see_diff_info: "Xem thông tin truy xuất khác",
    headquarters: "TRỤ SỞ CHÍNH",

    ID_GS1: "Mã GS1",
    branch: "CÁC CHI NHÁNH",
    address_area: "Địa chỉ sản xuất",
    unit: "Đơn vị",

    expDate: "Ngày sản xuất",
    certQuality: "Chứng nhận chất lượng",
    traceID: "Mã tra cứu",

    diaryFirst: "Thông tin được",
    diarySecond: "ghi nhật ký trong quá trình sản xuất và đảm bảo bởi công nghệ Blockchain"
  },

  fr: {
    input_lookup_code: "Entrer/Lire la cl de produit",
    search: "Chercher",
    ID_code_object: "Code d’objet",
    registration_date: "Date d’inscription",
    phone: "Numéro de téléphone",
    email: "Courriel",
    address: "Adresse",
    view_detail_overview: "Voir les détails",
    collapse: "Réduire",
    blockchain_info: "Information sur la blockchain",
    unit: "Unité",
    actions: "Activités",
    blockchain_evidence: "Authentification sur la plateforme de Blockchain",
    images: "Images",
    detail: "Détails",
    details: "Détails",
    who: "Personne",
    proof: "Authentification sur la plateforme de Blockchain"
  }
});

// strings.setLanguage('vi');

export default strings;
