import React from "react";
import Header from "./components/Header";
import News from "./components/News";
import Products from "./components/Product";
import Manufacturer from "./components/Manufacturer";
import Footer from "./components/Footer";
// import Advertisements from "./components/Advertisements";
import "./css/index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAdsList } from "actions/advertisement";
// import AdsSlideShow from "./components/AdsSlideShow";

// const adsList = [
//   {
//     show: true,
//     images: [
//       {
//         image: {
//           secure_url: 'https://res.cloudinary.com/dinhtq98/image/upload/v1607438509/Kontum%20Project/FreshVegetableStandee_lfz88t.png'
//         }
//       }
//     ]
//   },
//   {
//     show: true,
//     amongPagePosition: 'above-content',
//     images: [
//       {
//         image: {
//           secure_url: 'https://res.cloudinary.com/dinhtq98/image/upload/v1607442268/Kontum%20Project/6_p7sscb.png'
//         }
//       },
//       {
//         image: {
//           secure_url: 'https://res.cloudinary.com/dinhtq98/image/upload/v1607442268/Kontum%20Project/14_lztphm.png'
//         }
//       }
//     ]
//   },
//   {
//     show: true,
//     images: [
//       {
//         image: {
//           secure_url: 'https://res.cloudinary.com/dinhtq98/image/upload/v1607438509/Kontum%20Project/FreshVegetableStandee_1_u6ujb7.png'
//         }
//       }
//     ]
//   }
// ]

const Info = (props) => {
  // const [adsList, setAdsList] = useState([]);
  // const getAdsList = props.getAdsList;
  // useEffect(() => {
  //   getAdsList();
  // }, [getAdsList]);

  // useEffect(() => {
  //   const temp = props.adsList;
  //   setAdsList(temp);
  // }, [props.adsList]);

  return (
    <>
      {/* <Advertisements /> */}
      <Header />
      {/* {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "above-content" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null} */}
      <Products />
      {/* {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-product" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null} */}
      <Manufacturer />
      {/* {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-content" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null} */}
      <News />
      {/* {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-news" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null} */}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  adsList: state.advertisement.adsList,
});
const mapDispatchToProps = {
  getAdsList,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Info));
