import React from "react";
import { connect } from "react-redux";

SupportContact.propTypes = {};

function TriggerOutlook(email) {
  var body = "";

  var subject = "GÓP Ý HỆ THỐNG TRUY XUẤT NGUỒN GỐC";

  window.location.href = `mailto:${email}?body=` + body + "&subject=" + subject;
}

function SupportContact(props) {
  const contact = props.contact;
  const [contactInfo, setContact] = React.useState({});

  React.useEffect(() => {
      if (contact) {
        setContact(contact);
      }
  }, [contact]);

  if (!(contactInfo && contactInfo.email)) {
    return null;
  }

  return (
    <a onClick={() => TriggerOutlook(contactInfo.email)} className="fixed-support-contact">
      <div className="ring">
          <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
              <div className="coccoc-alo-ph-circle"></div>
              <div className="coccoc-alo-ph-circle-fill"></div>
              <div className="coccoc-alo-ph-img-circle"></div>
          </div>
      </div>
    </a>
  );
}

const mapStateToProps = (state) => ({
  contact: state.contact.contact,
});

export default connect(mapStateToProps)(SupportContact);
