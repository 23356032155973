import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
// import moment from "moment";
import "react-perfect-scrollbar/dist/css/styles.css";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNewsList, getOpInfoList } from "actions/news";
import { getMediaList } from "actions/media";
import { cleanStore } from "actions/index";
import { stripHtml, truncate } from "../../../utils";
// import CalendarIcon from "mdi-react/CalendarIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import BullhornIcon from "mdi-react/BullhornIcon"

import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from "react-awesome-slider/src/core/styles.scss";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const News = (props) => {
  const [newsList, setNewsList] = useState([]);
  const [opInfoList, setDocumentList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const {getNewsList, getOpInfoList, getMediaList} = props;
  useEffect(() => {
    getNewsList({ page: 1, per_page: 10 });
    getOpInfoList({ page: 1, per_page: 4 });
    getMediaList();

    return () => props.cleanStore();
  }, [getNewsList, getOpInfoList, getMediaList]);

  useEffect(() => {
    const data = props.newsList || [];
    setNewsList(data);
  }, [props.newsList]);

  useEffect(() => {
    const data = props.opInfoList || [];
    setDocumentList(data);
  }, [props.opInfoList]);

  useEffect(() => {
    const mediaData = props.mediaList || [];
    if (mediaData.length && props.newsList && props.newsList.length) {
      const filteredMedia = props.newsList.map((ele, idx) => {
        return mediaData.find((o) => ele.featured_media === o.id) || {};
      });
      setMediaList(filteredMedia);
    }
  }, [props.mediaList, props.newsList]);

  // Check data being fetched from sever, if not, render Skeletons
  if (!newsList || !newsList.length || !mediaList || !mediaList.length) {
    return (
      <>
        <Container>
          <Row className="mt-3 mb-3">
            <Col md="8">
              <div className="w-100 border-red pb-2">
                <div className="d-inline-block pb-2 pt-2 mt-3 ">
                  <span className="font-weight-bold text-red">
                    TIN TỨC NỔI BẬT
                  </span>
                </div>
              </div>

              <Row className="mt-3">
                <Col md="12">
                  <div className="w-100">
                    <img
                      src={
                        "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                      }
                      alt="noImage"
                    />
                  </div>
                  <div className="w-100 mt-3 ">
                    <Skeleton animation="wave" />
                  </div>
                  <div className="w-100 mt-1" style={{ textAlign: "justify" }}>
                    <Skeleton animation="wave" />
                  </div>
                </Col>
                {/* <Col md="5">
                  {[1, 2, 3, 4].map((element, idx) => {
                    if (idx > 0) {
                      return (
                        <div key={idx} className="d-flex flex-row mb-1">
                          <img
                            className="mx-2 sub-image"
                            src={
                              "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                            }
                            alt="noImage"
                          />
                          <div className="sub-content d-flex flex-column">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </div>
                          <div className="divider"></div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </Col> */}
              </Row>
            </Col>
            <Col md="4">
              <div className="w-100 border-red pb-2">
                <div className="d-inline-block pb-2 pt-2 mt-3 ">
                  <span className="font-weight-bold text-red">
                    THÔNG TIN ĐIỀU HÀNH
                  </span>
                </div>
              </div>
              <Row className="mt-3">
                <Col md="12">
                  {[1, 2, 3, 4].map((item, idx) => (
                    <>
                      <div
                        className="w-100 font-size-xs rounded bg-neutral-success"
                        key={idx}
                      >
                        <Skeleton animation="wave" />
                      </div>
                      <hr />
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <Skeleton animation="wave" variant="rect" />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col xl="8" lg="7" md="7">
            <div className="w-100 border-red pb-2">
              <div className="d-inline-block pb-2 pt-2 mt-3 ">
                <span className="font-weight-bold text-red">
                  TIN TỨC NỔI BẬT
                </span>
              </div>
              <a className="fl-right pr-3 mt-4" href="/tin-tuc">
                Xem tất cả <ChevronRightIcon />
              </a>
            </div>

            <Row className="mt-3">
              <Col id="news" md={12}>
                <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false}
                  interval={4000}
                  bullets={false}
                  cssModule={AwsSliderStyles}
                >
                  {newsList.map((element, idx) => {
                    if (idx < 5) {
                      return (
                        <>
                          <div className="w-100">
                            <a
                              href={`/tin-tuc/${element.id}/${element.slug}`}
                              className="main-title"
                            >
                              <img
                                style={{height: "360px"}}
                                src={
                                  mediaList[idx] && mediaList[idx].source_url
                                    ? mediaList[idx].source_url
                                    : "https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png"
                                }
                                alt="noImage"
                              />
                            </a>
                            <div className="title-container">
                              <a
                                href={`/tin-tuc/${element.id}/${element.slug}`}
                                className="main-title"
                              >
                                {element.title
                                  ? truncate(stripHtml(element.title.rendered), 100)
                                  : ""}
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
                </AutoplaySlider>
              </Col>
            </Row>
          </Col>
          <Col xl="4" lg="5" md="5">
            <div className="w-100 border-red pb-2">
              <div className="d-inline-block pb-2 pt-2 mt-3 ">
                <span className="font-weight-bold text-red">
                  THÔNG TIN ĐIỀU HÀNH
                </span>
              </div>
            </div>
            <Row className="mt-3">
              <Col md="12">
                {opInfoList &&
                  opInfoList.map((item, idx) => (
                    <>
                      <div
                        className="w-100 font-size-xs rounded"
                        key={idx}
                      >
                        <BullhornIcon size={14} className="mr-2" />
                        <a
                          href={`/thong-tin-dieu-hanh/${item.id}/${item.slug}`}
                          title={stripHtml(item.title.rendered)}
                          className="sub-title font-size-xs"
                        >
                          {truncate(stripHtml(item.title.rendered), 130)}
                        </a>
                      </div>
                      <hr style={{borderTop: "1px dashed #666"}}/>
                    </>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row className="mt-3">
          <Col md="12">
            <img
              src="https://res.cloudinary.com/agridential/image/upload/v1603445040/AGDImage/92_k7ydtu.png"
              alt="eventImg"
              style={{ width: "100%" }}
            />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newsList: state.news.newsList,
    mediaList: state.media.mediaList,
    opInfoList: state.news.opInfoList,
    loading: state.news.loading,
  };
};

const mapDispatchToProps = {
  getNewsList,
  getMediaList,
  getOpInfoList,
  cleanStore,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));
